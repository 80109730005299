import * as React from "react";
import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

const ContactForm = ({ preselectedSubject }) => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const onClick = async (e) => {
    e.preventDefault();
    const formData = new FormData(document.getElementById("contact_form"));

    const validation = {};

    if (!formData.get("phone") && !formData.get("email")) {
      validation.phone = "Podaj numer telefonu lub email";
      validation.email = "Podaj numer telefonu lub email";
    }

    if (formData.get("email") && !formData.get("email").match(/^.+@.+$/)) {
      validation.email = "Podaj poprawny adres email";
    }

    if (!formData.get("message") || formData.get("message").length < 10) {
      validation.message = "Wpisz wiadomość (min. 10 znaków)";
    }

    if (Object.keys(validation).length) {
      setError(validation);
      return;
    }

    try {
      const response = await fetch("/api/contact-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(Object.fromEntries(formData.entries()))
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        // todo: handle error
      }

    } catch (error) {
      console.error(error);
    }
  };

  const [city, setCity] = useState('szczecin');

  const getContactFormOptions = (city) => {
    if (city === 'szczecin') {
      return (
      <>
        <option value="visit-date">Termin wizyty</option>
        <option value="all-on-4">All-on-4&trade;</option>
        <option value="other">Inny</option>
        </>
      );
    }

    return (
      <>
        <option value="visit-date">Termin wizyty</option>
        <option value="all-on-4">All-on-4&trade;</option>
        <option value="nfz">NFZ</option>
        <option value="other">Inny</option>
      </>
    );
  }

  if (submitted) {
    return <div className="font-lato text-blue-950 text-lg font-bold">Dziękujemy za zgłoszenie, skontaktujemy się z
      Państwem.</div>;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 scroll-mt-20" id="contact-form">
      <div className="w-full">
        <h2 className="font-lato font-bold text-blue-950 text-2xl">Chcesz umówić się na wizytę? <br /> Wypełnij
          formularz</h2>
        <form id="contact_form" className="flex flex-col gap-4 font-lato text-blue-950" onChange={() => setError(null)}>
          <fieldset className="flex gap-4" onChange={(event) => setCity(event.target.value)}>
            <legend className="mb-1 font-bold">Miasto</legend>
            <div>
              <input id="city1" type="radio" name="city" value="szczecin" defaultChecked className="mr-2" />
              <label htmlFor="city1">Szczecin</label>
            </div>
            <div>
              <input id="city2" type="radio" name="city" value="gorzow" className="mr-2" />
              <label htmlFor="city2">Gorzów Wielkopolski</label>
            </div>
          </fieldset>
          <div className="flex flex-col lg:flex-row gap-4 w-full">
            <div className="flex flex-col basis-1/2">
              <label htmlFor="phone" className="mb-1 font-bold">Telefon</label>
              <input type="text" name="phone"
                     className={error?.phone ? 'border border-red-500 rounded-sm px-1 h-10' : 'border border-[#898CB1] rounded-sm px-1 h-10'}
                     required />
              {error?.phone && <small className="text-red-500">{error.phone}</small>}
            </div>
            <div className="flex flex-col basis-1/2">
              <label htmlFor="email" className="mb-1 font-bold">Email</label>
              <input type="email" name="email"
                     className={error?.email ? 'border border-red-500 rounded-sm px-1 h-10' : 'border border-[#898CB1] rounded-sm px-1 h-10'}
                     required />
              {error?.email && <small className="text-red-500">{error.email}</small>}
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="subject" className="mb-1 font-bold">Temat</label>
            <select name="subject" id="contact_form_subject"
                    className="border border-[#898CB1] rounded-sm px-1 h-10" required defaultValue={preselectedSubject}>
              {getContactFormOptions(city)}
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="message" className="mb-1 font-bold">Wiadomość</label>
            <textarea name="message" defaultValue="Proszę o kontakt w sprawie wizyty"
                      className={error?.message ? 'border border-red-500 rounded-sm px-1 h-24' : 'border border-[#898CB1] rounded-sm px-1 h-24'}
                      required />
            {error?.message && <small className="text-red-500">{error.message}</small>}
          </div>
          <p className="font-lato text-blue-950 mb-4">Skontaktujemy się z Państwem w godzinach pracy recepcji, tj. od
            9:00 do 18:00 od poniedziałku do piątku.</p>
          <button type="submit" onClick={onClick} data-track-click data-track-category="leadgen"
                  data-track-component="contact_form"
                  className="bg-purpleHeart hover:bg-chetwodeBlue rounded-full w-24 h-8 p-1 text-white font-bold text-sm mb-2">
            Wyślij
          </button>
          <small>Klikając “Wyślij” wyrażam zgodę na przetwarzanie danych osobowych zgodnie z Ustawą o Ochronie Danych
            Osobowych w związku z wysłaniem zapytania za pośrednictwem formularza kontaktowego. Podanie danych jest
            dobrowolne, lecz niezbędne do przetworzenia zapytania oraz umówienia wizyty w gabinecie. Otrzymałem
            informację, że przysługują mi prawa dostępu do moich danych, możliwość ich poprawienia oraz wniesienia
            żądania
            zaprzestania przetwarzania. Administratorem danych osobowych jest Aesthetic Dent Tutak spółka z ograniczoną
            odpowiedzialnością, z siedzibą przy ul. Wyspiańskiego 7, 70-497 Szczecin.</small>
        </form>
      </div>
      <StaticImage src="../images/zespol.jpg" alt="" />
    </div>
  );
};

export default ContactForm;
