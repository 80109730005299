import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Layout from "components/Layout";
import PictureCaption from "components/PictureCaption";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "protetyka.korony" },
  { key: "protetyka" },
  { key: "implanty" },
  { key: "stomatologia-estetyczna" },
];

const Stomatologia3DPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "oferta/stomatologia-3d-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      image2: file(relativePath: { eq: "oferta/stomatologia-3d-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const { formatMessage } = useIntl();

  return (
    <>
      <SEO title={formatMessage({ id: "oferta.protetyka.stomatologia-3d.seo.title" })} />
      <main>
        <article className={styles.article}>
          <section>
            <h1>{formatMessage({ id: "oferta.protetyka.stomatologia-3d.h1" })}</h1>
            <div className={styles.text}>
              <FormattedMessage id="oferta.protetyka.stomatologia-3d.p.1" tagName="p" />
              <FormattedMessage id="oferta.protetyka.stomatologia-3d.p.2" tagName="p" />
            </div>
            <div className="double-picture-row">
              <figure className={styles.picture}>
                <FormattedMessage id="oferta.protetyka.stomatologia-3d.picture.1">
                  {(msg) => (
                    <>
                      <Img fluid={data.image1.childImageSharp.fluid} alt={msg} />
                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
              <figure className={styles.picture}>
                <FormattedMessage id="oferta.protetyka.stomatologia-3d.picture.2">
                  {(msg) => (
                    <>
                      <Img fluid={data.image2.childImageSharp.fluid} alt={msg} />

                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default Stomatologia3DPage;
