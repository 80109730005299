// extracted by mini-css-extract-plugin
export var address = "Footer-module--address--e79cb";
export var bigText = "Footer-module--big-text--9f719";
export var bottom = "Footer-module--bottom--217fa";
export var contact = "Footer-module--contact--2fc21";
export var footer = "Footer-module--footer--2d913";
export var layout = "Footer-module--layout--17146";
export var nav = "Footer-module--nav--fce49";
export var navList = "Footer-module--navList--f50fd";
export var offer = "Footer-module--offer--497d7";
export var richText = "Footer-module--rich-text--6465c";
export var text = "Footer-module--text--94146";