import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { injectIntl } from "react-intl";

import locales from "../i18n/locales";

const LocalizedLink = ({ to, intl: { locale }, activeClassName, partiallyActive, children, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    const path = locales[locale]?.default ? to : `/${locale}${to}`;

    return <GatsbyLink 
      to={path}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      {...other}
      >
        {children}
      </GatsbyLink>;
  }

  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
};

export default injectIntl(LocalizedLink);
