import React, { forwardRef } from 'react';

const ModalBackdrop = forwardRef((props, ref) => {
    const { children, onClick: onBackdropClick } = props;

    const onClick = (event) => {
        if (event.target === event.currentTarget) {
            onBackdropClick();
        }
    }

    return (
        <div className="w-full h-full lg:backdrop-blur bg-slate-50/30 fixed top-0 left-0 z-[100] lg:p-8" onClick={onClick} ref={ref}>
            {children}
        </div>
    );
});

export default ModalBackdrop;