import React from "react";
import { useIntl } from "react-intl";

import LocalizedLink from "components/LocalizedLink";

import * as styles from "styles/404.module.scss";

const NotFoundPage = () => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      <div className={styles.error}>
        <section>
          <h2>{t({ id: "404.title" })}</h2>
          <div className={styles.error__info}>
            {t({ id: "404.p" })}
            <LocalizedLink to="/" className={styles.more}>
              {t({ id: "404.a.index" })}
            </LocalizedLink>
          </div>
        </section>
      </div>
    </>
  );
};

export default NotFoundPage;
