import React, { forwardRef } from "react";

//TODO aria & translations
export const ModalCloseButton = forwardRef((props, ref) => {
    const { onClick } = props;

    return (
        <div className="relative h-8 mb-4">
            <button ref={ref} className="absolute -top-[1px] -right-[1px] p-4 border border-solid border-slate-200 hover:bg-slate-50 focus:ring-2 ring-blue-200 ring-inset active:bg-slate-200" onClick={onClick}>
                <svg className="w-4 h-4" viewBox="0 0 32 31" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                        <g transform="translate(-1357.000000, -318.000000)" strokeWidth="2" className="stroke-slate-400">
                            <path d="M1388.07743,318.138298 L1358.07743,348.138298 M1388.07743,348.138298 L1358.07743,318.138298"></path>
                        </g>
                    </g>
                </svg>
            </button>
        </div>
    );
});

export default ModalCloseButton;