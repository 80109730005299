import React from "react";

import * as styles from "./Hamburger.module.scss";

const Hamburger = ({ toggle, onClick }) => {
  return (
    <button
      className={`${styles.menu} ${toggle && styles.toggled}`}
      onClick={onClick}
      aria-label="Menu"
      aria-expanded={toggle}
      aria-controls="drawer-menu"
    >
      <span className={styles.top} />
      <span className={styles.middle} />
      <span className={styles.bottom} />
    </button>
  );
};

export default Hamburger;
