// extracted by mini-css-extract-plugin
export var article_container = "cennik-module--article_container--be8e1";
export var article_inner = "cennik-module--article_inner--67e28";
export var article_inner__header = "cennik-module--article_inner__header--e3452";
export var bigText = "cennik-module--big-text--331b6";
export var bottom = "cennik-module--bottom--3ac29";
export var cennik = "cennik-module--cennik--7cbda";
export var centered = "cennik-module--centered--97068";
export var container = "cennik-module--container--dffc0";
export var container__inner = "cennik-module--container__inner--ba016";
export var desktopNone = "cennik-module--desktop-none--72677";
export var doublePictureRow = "cennik-module--double-picture-row--73017";
export var fade = "cennik-module--fade--3e95e";
export var fadeUp = "cennik-module--fadeUp--1b5e2";
export var fadeZoom = "cennik-module--fadeZoom--cec08";
export var inline = "cennik-module--inline--73805";
export var jsNoscroll = "cennik-module--js-noscroll--0e430";
export var jsVisible = "cennik-module--js-visible--ce6ad";
export var mobileNone = "cennik-module--mobile-none--f8634";
export var pictures = "cennik-module--pictures--c2b3b";
export var richText = "cennik-module--rich-text--defdd";
export var singlePicture = "cennik-module--single-picture--94980";
export var singlePictureRow = "cennik-module--single-picture-row--573d0";
export var text = "cennik-module--text--d6bf9";
export var text__big = "cennik-module--text__big--9ce89";