import Breadcrumbs from "components/Breadcrumbs";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Layout from "components/Layout";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [{ key: "protetyka" }, { key: "ortodoncja" }, { key: "implanty" }];

const StomatologiaEstetycznaPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({
          id: "oferta.stomatologia-estetyczna.seo.title",
        })}
        description={formatMessage({
          id: "oferta.stomatologia-estetyczna.seo.description",
        })}
      />
      <main>
        <article className={styles.article}>
          <section>
            <h1>{formatMessage({ id: "oferta.stomatologia-estetyczna.h1" })}</h1>
            <div className={styles.text}>
              <FormattedMessage id="oferta.stomatologia-estetyczna.p.1" tagName="p" />
              <FormattedMessage id="oferta.stomatologia-estetyczna.p.2" tagName="p" />
              <FormattedMessage id="oferta.stomatologia-estetyczna.p.3" tagName="p" />
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default StomatologiaEstetycznaPage;
