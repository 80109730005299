export const services = [
  { key: "protetyka" },
  { key: "ortodoncja" },
  { key: "ortognatyka" },
  { key: "implanty" },
  { key: "chirurgia-stomatologiczna" },
  { key: "stomatologia-estetyczna" },
  { key: "stomatologia-zachowawcza" },
  { key: "endodoncja" },
  { key: "radiologia" },
  { key: "stomatologia-maloinwazyjna" },
  { key: "profilaktyka" },
  { key: "higienizacja" },
];
