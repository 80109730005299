import React from "react";
import { FormattedMessage } from "react-intl";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from "../components/Accordion";
import SEO from "../components/Seo";
import PageIntro from "common/PageIntro";

import * as styles from "styles/faq.module.scss";
import * as pageStyles from "styles/page-styles.module.scss";
import FaqJsonLd from "components/FaqJsonLd";

const FAQPage = () => {
  return (
    <>
      <FaqJsonLd />
      <>
        <SEO title="FAQ" />
        <main>
          <PageIntro title="FAQ" subtitle="Pytania i odpowiedzi." centered />
          <article>
            <section className={pageStyles.baseSection}>
              <div className={styles.wrapper}>
                <FormattedMessage id="faq.klinika.title" tagName="h3" />
                <Accordion>
                  {[1, 2, 3, 4, 5].map((i) => (
                    <AccordionItem key={i}>
                      <AccordionItemHeading ariaLevel={2}>
                        <FormattedMessage id={`faq.klinika.pytanie.${i}`} tagName="h3" />
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <FormattedMessage id={`faq.klinika.odpowiedz.${i}`} tagName="p" />
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </section>
          </article>
        </main>
      </>
    </>
  );
};
export default FAQPage;
