import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Layout from "components/Layout";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "licowki-i-kompozyty" },
  { key: "protetyka.stomatologia-3d" },
  { key: "implanty" },
  { key: "protetyka" },
  { key: "stomatologia-estetyczna" },
];

const KoronyPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO title={formatMessage({ id: "oferta.protetyka.korony.seo.title" })} />
      <main>
        <article className={styles.article}>
          <section>
            <h1>{formatMessage({ id: "oferta.protetyka.korony.h1" })}</h1>
            <div className={styles.text}>
              <FormattedMessage id="oferta.protetyka.korony.p.1" tagName="p" />
              <FormattedMessage id="oferta.protetyka.korony.p.2" tagName="p" />
              <FormattedMessage id="oferta.protetyka.korony.p.3" tagName="p" />
              <FormattedMessage id="oferta.protetyka.korony.p.4" tagName="p" />
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default KoronyPage;
