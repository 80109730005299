import React from "react";
import { graphql } from "gatsby";
import classnames from "classnames";

import SEO from "components/Seo";

import { appContainer } from "styles/page-styles.module.scss";
import { articleWrapper, article } from "styles/article-styles.module.scss";

const Component = ({ data }) => {


  const { articleContent } = data.allStrapiPatientsSection.edges[0].node;
  return (
    <main>
      <div className={articleWrapper}>
        <article
          className={classnames(appContainer, article)}
          dangerouslySetInnerHTML={{ __html: articleContent.data.childrenMarkdownRemark[0].html }}
        />
      </div>
    </main>
  );
};

export const Head = ({ data }) => {
  const { seo } = data.allStrapiPatientsSection.edges[0].node;

  return <SEO title={seo.metaTitle} description={seo.metaDescription} />;
}

export const pageQuery = graphql`
  query PatientsSectionQuery($id: String) {
    allStrapiPatientsSection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          title
          articleContent {
            data {
              childrenMarkdownRemark {
                html
              }
            }
          }
          seo {
            metaDescription
            metaTitle
          }
          locale
        }
      }
    }
  }
`;

export default Component;
