import PageIntro from "common/PageIntro";
import { withPrefix } from "gatsby";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import LocalizedLink from "../components/LocalizedLink";
import SEO from "../components/Seo";
import * as styles from "../styles/strefa-pacjenta.module.scss";

const StrefaPacjentaPage = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SEO
        title={formatMessage({ id: "strefa-pacjenta.h1" })}
        description={formatMessage({ id: "strefa-pacjenta.seo.description" })}
      />
      <main>
        <PageIntro
          title="Strefa Pacjenta"
          subtitle="Informacje, materiały dla pacjentów."
          centered
        />
        <article className={styles.container}>
          <section>
            <div className={styles.text}>
              <FormattedMessage id="strefa-pacjenta.p" tagName="p" />
            </div>
            <ul className={styles.list}>
              <li>
                <FormattedMessage id="strefa-pacjenta.prawa-pacjenta">
                  {(msg) => (
                    <LocalizedLink to="/strefa-pacjenta/prawa-pacjenta/" className="link">
                      {msg}
                    </LocalizedLink>
                  )}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="strefa-pacjenta.obowiazki-pacjenta">
                  {(msg) => (
                    <LocalizedLink to="/strefa-pacjenta/obowiazki-pacjenta/" className="link">
                      {msg}
                    </LocalizedLink>
                  )}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="strefa-pacjenta.okres-przechowywania-dokumentacji-medycznej">
                  {(msg) => (
                    <LocalizedLink
                      to="/strefa-pacjenta/okres-przechowywania-dokumentacji-medycznej/"
                      className="link"
                    >
                      {msg}
                    </LocalizedLink>
                  )}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="strefa-pacjenta.udostepnianie-dokumentacji-medycznej">
                  {(msg) => (
                    <LocalizedLink
                      to="/strefa-pacjenta/udostepnianie-dokumentacji-medycznej/"
                      className="link"
                    >
                      {msg}
                    </LocalizedLink>
                  )}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="strefa-pacjenta.udostepnienie-dokumentacji-medycznej.wniosek">
                  {(msg) => (
                    <a
                      rel="noopener noreferrer"
                      href={withPrefix("wnioski/udostepnienie-dokumentacji-medycznej.pdf")}
                      target="_blank"
                      className="link"
                    >
                      {msg}
                    </a>
                  )}
                </FormattedMessage>
              </li>
              <li>
                <FormattedMessage id="strefa-pacjenta.polityka-prywatnosci">
                  {(msg) => (
                    <LocalizedLink to="/strefa-pacjenta/polityka-prywatnosci/" className="link">
                      {msg}
                    </LocalizedLink>
                  )}
                </FormattedMessage>
              </li>
            </ul>
          </section>
        </article>
      </main>
    </>
  );
};

export default StrefaPacjentaPage;
