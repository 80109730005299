import React, { useEffect } from 'react';
import { FocusOn } from 'react-focus-on';

import ModalBackdrop from './ModalBackdrop';
import ModalCloseButton from './ModalCloseButton';


const Modal = (props) => {
    const { open, onClose, children } = props;

    const closeModalOnEscKeyPress = (event) => {
        event.keyCode === 27 && onClose();
    };

    useEffect(() => {
        document.addEventListener('keydown', closeModalOnEscKeyPress);

        return () => {
            document.removeEventListener('keydown', closeModalOnEscKeyPress);
        };
    }, [open]);

    if (!open) {
        return null;
    }

    return (
        <FocusOn>
            <ModalBackdrop onClick={onClose}>
                <div className="mx-auto w-full h-full bg-white max-w-5xl md:border border-solid border-slate-200 rounded-sm overflow-hidden">
                    <ModalCloseButton onClick={onClose} />
                    <div className="w-full h-full overflow-x-hidden overflow-y-auto modal" tabIndex={0}>
                        {children}
                    </div>
                </div>
            </ModalBackdrop>
        </FocusOn>
    );
}

export default Modal;