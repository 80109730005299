import React from 'react';
import { graphql } from "gatsby";
import BlogPostCard from 'components/BlogPostCard';
import { getImage } from "gatsby-plugin-image";

const mapToBlogPostCard = (node) => {
    const { title, pathname, publicationDate, article, featured } = node;
    const { excerpt } = article?.data?.childrenMarkdownRemark?.[0] ?? "";
    const { alternativeText, localFile } = featured ?? {};
    const image = getImage(localFile);

    return {
        title,
        excerpt,
        image,
        publicationDate,
        pathname,
    }
}

const Component = ({ data }) => {
    const { edges } = data.allStrapiNewsPost;

    return (
        <main className="main max">
            <div className="px-m">
                <h1 className="h">Aktualności</h1>
            </div>
            <div>
                {edges.map(({ node }) => <BlogPostCard {...mapToBlogPostCard(node)} key={node.id} />)}
            </div>
        </main>
    );
};

export const Head = () => {
    return (
        <div>
            <h1>Head</h1>
        </div>
    );
};

export const pageQuery = graphql`
  query($locale: String) {
    allStrapiNewsPost {
      edges {
        node {
          id
          title
          pathname: gatsbyPath(filePath:"/aktualnosci/{strapiNewsPost.title}")
          publicationDate(formatString: "DD MMMM, yyyy", locale: $locale)
          article {
            data {
              childrenMarkdownRemark {
                excerpt(truncate: true)
              }
            }
          }
          featured {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(aspectRatio: 1.78, width: 600)
              }
            }
          }
        }
      }
    }
  }
`

export default Component;