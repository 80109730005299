import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import SEO from "components/Seo";

const HigienizacjaPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({ id: "oferta.profilaktyka.h1" })}
        description={formatMessage({
          id: "oferta.profilaktyka.seo.description ",
        })}
      />
      <main className="main max px-m">
        <article>
          <section>
          <h1 className="h text-blue-950">Higienizacja zębów</h1>
          <h2 className="h text-2xl text-blue-950 my-5">Czym jest higienizacja zębów?</h2>
          <p>
          Higienizacja zębów to profesjonalne czyszczenie zębów wykonywane przez stomatologa lub higienistkę stomatologiczną. Jest to jeden z podstawowych zabiegów profilaktycznych oferowanych przez Aesthetic Dent. Zabieg ten ma na celu usunięcie kamienia nazębnego, płytki bakteryjnej oraz przebarwień, które mogą prowadzić do chorób zębów i dziąseł.
          </p>
          </section>
          <section>
          <h2 className="h text-2xl text-blue-950 my-5">Jak wygląda higienizacja zębów i co wchodzi w jej skład?</h2>
          <p>
            <ul className="list-outside list-disc marker:font-bold marker:text-blue-200 pl-4">
                <li className="my-3">
                    <strong>Wybarwianie erytrozyną:</strong> proces higienizacji zaczyna się od wybarwienia płytki nazębnej specjalnym barwnikiem - erytrozyną. Pozwala to na dokładne zobrazowanie miejsc, w których zgromadziła się płytka bakteryjna, co ułatwia jej usunięcie. Równocześnie pozwala pokazać pacjentowi miejsca które powinien szczególnie doczyszczać podczas codziennego szczotkowania zębów.
                </li>
                <li className="my-3">
                    <strong>Skaling:</strong> To zabieg usuwania kamienia nazębnego za pomocą ultradźwięków lub narzędzi ręcznych. 
                </li>
                <li className="my-3">
                    <strong>Piaskowanie:</strong> Polega na usuwaniu bakteryjnej płytki nazębnej i przebarwień za pomocą  strumienia powietrza, wody i drobnych cząsteczek ściernych.
                </li>
                <li className="my-3">
                    <strong>Polerowanie:</strong> Po skalingu i piaskowaniu, zęby są polerowane za pomocą specjalnych past polerskich, co pomaga w wygładzeniu powierzchni zębów i utrudnia ponowne osadzanie się kamienia.
                </li>
                <li className="my-3">
                    <strong>Fluoryzacja:</strong> Na koniec zęby są pokrywane preparatem zawierającym fluor, który wzmacnia szkliwo i chroni przed próchnicą. Fluoryzacja zębów to ważny etap higienizacji, który pomaga w remineralizacji szkliwa.
                </li>
                <li className="my-3">
                    <strong>Instruktaż higieny:</strong> Pacjenci otrzymują szczegółowe wskazówki dotyczące prawidłowej higieny jamy ustnej, w tym technik szczotkowania i nitkowania zębów oraz stosowania płynów do płukania ust.
                </li>
            </ul>
            </p>
            </section>
            <section>
            <h2 className="h text-2xl text-blue-950">
                Dla kogo zalecana jest higienizacja zębów i jak często?
            </h2>
            <p>
                Higienizacja zębów zalecana jest dla wszystkich pacjentów, niezależnie od wieku. Zaleca się wykonywanie higienizacji przynajmniej raz w roku,  aby skutecznie zapobiegać problemom stomatologicznym i utrzymywać zdrową jamę ustną.
            </p>
            <p>
            Higienizacja jest szczególnie zalecana w następujących przypadkach:
            <ul className="list-outside list-disc marker:font-bold marker:text-blue-200 pl-4">
                <li className="my-3">
                    <strong>Palenie papierosów:</strong> Palenie szczególnie przyczynia się do powstawania kamienia nazębnego i przebarwień.
                </li>
                <li className="my-3">
                    <strong>Picie kawy i herbaty:</strong> Higienizacja pomaga usuwać przebarwienia powstałe na skutek spożywania tych napojów.
                </li>
                <li className="my-3">
                    <strong>Genetyczna tendencja do odkładania się kamienia nazębnego:</strong> Niektóre osoby są bardziej narażone na odkładanie się kamienia, co wymaga regularnego usuwania.
                </li>
                <li className="my-3">
                    <strong>Profilaktyka przed próchnicą oraz chorobami przyzębia:</strong> Regularne czyszczenie zębów pomaga w zapobieganiu tym chorobom.
                </li>
                <li className="my-3">
                    <strong>Przygotowanie jamy ustnej do zabiegu wybielania zębów:</strong> Dokładne oczyszczenie zębów przed wybielaniem jest kluczowe dla uzyskania równomiernych efektów.
                </li>
                <li className="my-3">
                    <strong>Noszenie aparatu ortodontycznego:</strong> Aparaty utrudniają samodzielne utrzymanie odpowiedniej higieny jamy ustnej oraz jej dokładne oczyszczanie, co zwiększa ryzyko próchnicy i chorób dziąseł.
                </li>
                <li className="my-3">
                    <strong>Implanty stomatologiczne:</strong> Regularna higienizacja jest niezbędna w utrzymaniu zdrowia tkanek wokół implantów i zapobieganiu stanom zapalnym.
                </li>
            </ul>
            Regularna higienizacja zębów przynosi liczne korzyści, takie jak usunięcie kamienia nazębnego i płytki bakteryjnej, zmniejszenie ryzyka próchnicy i chorób dziąseł, poprawa estetyki uśmiechu, wzmocnienie szkliwa dzięki fluoryzacji oraz utrzymanie świeżego oddechu.
            </p>
          </section>
          <section>
            <h2 className="h text-2xl text-blue-950 my-5">Cena zabiegu i czy jest bolesny?</h2>
            <p>
            Koszt higienizacji zębów zależy od zakresu zabiegu. W naszej placówce koszt pełnego zabieg (wybarwianie erytrozyną, skaling, piaskowanie, polerowanie i fluoryzacja) wynosi 400 zł. Zabieg zazwyczaj nie jest bolesny, chociaż niektóre osoby mogą odczuwać dyskomfort, zwłaszcza jeśli mają wrażliwe dziąsła. Wówczas możliwe jest wykonanie znieczulenia miejscowego dla poprawy komfortu zabiegu. 
            </p>
          </section>
          <section>
            <h2 className="h text-2xl text-blue-950 my-5">Czas trwania zabiegu</h2>
            <p>
                Higienizacja zębów trwa zwykle od 45 do 60 minut, w zależności od ilości kamienia nazębnego i stanu jamy ustnej pacjenta.
            </p>
          </section>
          <section>
            <h2 className="h text-2xl text-blue-950 my-5">
                Stany zagrożenia przy zaniedbaniu higieny jamy ustnej
                </h2>
            <p>
            Zaniedbanie higieny jamy ustnej może prowadzić do:
            <ul className="list-outside list-disc marker:font-bold marker:text-blue-200 pl-4">
                <li className="my-3">
                    Próchnicy
                    </li>
                <li className="my-3">
                    Chorób dziąseł (zapalenie dziąseł, paradontoza)
                    </li>
                    <li className="my-3">
                        Utraty zębów
                        </li>
                        <li className="my-3">
                            Problemów z układem pokarmowym
                        </li>
                        <li className="my-3">
                        Pogorszenia ogólnego stanu zdrowia (choroby serca, miażdżyca, cukrzyca)
                        </li>
            </ul>
            </p>
          </section>
          <section>
            <h2 className="h text-2xl text-blue-950 my-5">
                Higienizacja zębów przy aparacie ortodontycznym i implantach
            </h2>
            <p>
            Higienizacja zębów jest szczególnie ważna dla osób noszących aparaty ortodontyczne oraz posiadających implanty. Zaniedbanie higieny przy aparacie ortodontycznym może prowadzić do demineralizacji szkliwa i przebarwień i rozwoju próchnicy. 
            </p>
            <p>
            W przypadku implantów zaś, brak odpowiedniej higieny może spowodować stany zapalne wokół implantów, co może prowadzić do ich utraty.
            </p>
            <p>
            W obu przypadkach zaleca się regularne wizyty u higienistki stomatologicznej, która pomoże w utrzymaniu odpowiedniej higieny jamy ustnej. Regularne wizyty u stomatologa, a także odpowiednia higiena domowa, są kluczowe dla utrzymania zdrowego uśmiechu przez całe życie, jak i oszczędzenia potencjalnych kosztów leczenia w przyszłości.
            </p>
          </section>
        </article>
      </main>
    </>
  );
};

export default HigienizacjaPage;
