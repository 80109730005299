import React from "react";
import { useIntl } from "react-intl";

import * as styles from "./TopBar.module.scss";

const TopBar = () => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      <div className="h-[4px] bg-accent/60 w-full" />
      <aside className={styles.topBar}>
        <div className={styles.layout}>
          <small>
            Szczecin:
            <a
              href={`tel: ${t({ id: "kontakt.w.tel" })}`}
              data-track-click
              data-track-category="info"
              data-track-component="phone"
            >
              {t({ id: "kontakt.w.tel" })}
            </a>
          </small>
          <small>
            Gorzów Wielkopolski:
            <a
              href={`tel: ${t({ id: "kontakt.g.tel" })}`}
              data-track-click
              data-track-category="info"
              data-track-component="phone"
            >
              {t({ id: "kontakt.g.tel" })}
            </a>
          </small>
        </div>
      </aside>
    </>
  );
};

export default TopBar;
