import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion";
import PictureCaption from "components/PictureCaption";
import SEO from "components/Seo";
import { range } from "utils/range";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "protetyka" },
  { key: "protetyka.korony" },
  { key: "chirurgia-stomatologiczna" },
];

const ImplantyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "oferta/implanty-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      image2: file(relativePath: { eq: "oferta/implanty-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({ id: "oferta.implanty.seo.title" })}
        description={formatMessage({ id: "oferta.implanty.seo.description" })}
      />
      <main>
        <article className={styles.article}>
          <section>
            <div className={styles.text}>
              <h1>{formatMessage({ id: "oferta.implanty.h1" })}</h1>
              <FormattedMessage id="oferta.implanty.p.1" tagName="p" />
              <FormattedMessage id="oferta.implanty.p.2" tagName="p" />
            </div>
            <div className="double-picture-row">
              <figure className={styles.picture}>
                <FormattedMessage id="oferta.implanty.picture.1">
                  {(msg) => (
                    <>
                      <Img fluid={data.image1.childImageSharp.fluid} alt={msg} />
                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
              <figure className={styles.picture}>
                <FormattedMessage id="oferta.implanty.picture.2">
                  {(msg) => (
                    <>
                      <Img fluid={data.image2.childImageSharp.fluid} alt={msg} />

                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
            </div>
          </section>
          <section>
            <div>
              <FormattedMessage id="oferta.implanty.przebieg.h2" tagName="h3" />
              <div className={styles.text}>
                <ol className={styles.ordered__special}>
                  {range(1, 8).map((i) => (
                    <FormattedMessage id={`oferta.implanty.przebieg.li.${i}`}>
                      {(msg) => (
                        <li>
                          {msg}
                          <FormattedMessage id={`oferta.implanty.przebieg.li.${i}.p`} tagName="p" />
                        </li>
                      )}
                    </FormattedMessage>
                  ))}
                </ol>
              </div>
            </div>
          </section>
          <section>
            <div className={styles.text}>
              <FormattedMessage id="oferta.implanty.czesc-chirurgiczna.h2" tagName="h3" />
              <FormattedMessage id="oferta.implanty.czesc-chirurgiczna.p.1" tagName="p" />
              <FormattedMessage id="oferta.implanty.czesc-chirurgiczna.p.2" tagName="p" />
            </div>
          </section>
        </article>
        <article className={styles.article}>
          <section>
            <h2>FAQ</h2>
            <Accordion>
              {range(1, 14).map((i) => (
                <AccordionItem>
                  <AccordionItemHeading ariaLevel={2}>
                    <FormattedMessage id={`faq.implanty.pytanie.${i}`} tagName="h3" />
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <FormattedMessage id={`faq.implanty.odpowiedz.${i}`} tagName="p" />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default ImplantyPage;
