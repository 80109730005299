import React from "react";
import { useIntl } from "react-intl";

import MyMap from "components/MyMap";
import SEO from "components/Seo";
import PageIntro from "common/PageIntro";
import ContactForm from "components/ContactForm";
import OutIcon from "images/svg/out";

const ContactPage = () => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      <SEO title={t({ id: "kontakt.h1" })} description={t({ id: "kontakt.seo.description" })} />
      <main className="main max">
        {/* <PageIntro title={t({ id: "kontakt.h1" })} subtitle={t({ id: "kontakt.subtitle" })} /> */}
        <div className="px-m">
          <h1 className="h">{t({ id: "kontakt.h1" })}</h1>
        </div>
        <div className="flex flex-col justify-center">
          <div className="mb-6 md:mb-16">
            <div className="mb-8 md:mb-12 grid md:grid-cols-2 gap-y-4 gap-x-12 items-center md:px-m">
              <MyMap center="Stanislawa+Wyspianskiego+7,Szczecin,PL" />
              <div className="px-m md:px-0">
                <h3 className="mb-2 h">Szczecin</h3>
                <address className="mb-6 not-italic">
                  <h4 className="mb-0 text-base font-lato text-gray-950 font-medium">{t({ id: "kontakt.w.ulica" })}</h4>
                  <h4 className="mb-2 text-base font-lato text-gray-950 font-medium">{t({ id: "kontakt.w.kod" })}</h4>
                  <div>
                    <a href={`tel:${t({ id: "kontakt.w.tel" })}`} className="text-link">
                      {t({ id: "kontakt.w.tel" })}
                    </a>
                  </div>
                  <div className="mb-2">
                    <a
                      href={`mailto:wyspianskiego@aestheticdent.pl`}
                      className="text-link"
                      data-track-click
                      data-track-category="info"
                      data-track-component="email"
                    >
                      wyspianskiego@aestheticdent.pl
                    </a>
                  </div>
                </address>
                <div className="mb-6">
                  <h4 className="mb-2 font-lato text-gray-950 font-bold">{t({ id: "kontakt.godziny-otwarcia" })}</h4>
                  <p>{t({ id: "kontakt.w.godziny-otwarcia" })}</p>
                </div>
                <div className="mb-6">
                  <h4 className="mb-2 font-lato text-gray-950 font-bold">Parking</h4>
                  <p className="mb-2">{t({ id: "kontakt.w.parking" })}</p>
                  <a
                    href="https://www.google.com/maps/place/NZOZ+Aesthetic+Dent+Stomatologia+Marcin+Tutak/@53.4417787,14.5303537,16z/data=!4m8!1m2!2m1!1saesthetic+dent+szczecin!3m4!1s0x47aa09944f43fedd:0x2ddd402cb152e685!8m2!3d53.442738!4d14.53297"
                    className="flex items-center standalone-link"
                  >
                    {t({ id: "kontakt.wskazowki-dojazdu" })} <OutIcon className="ml-1" />
                  </a>
                </div>
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-y-4 gap-x-12 items-center md:px-m">
              <MyMap center="Mostowa+12,Gorzow,PL" />
              <div className="px-m md:px-0">
                <h3 className="mb-2 h">Gorzów Wielkopolski</h3>
                <address className="mb-4 not-italic">
                  <h4 className="mb-0 text-base font-lato text-gray-950 font-medium">{t({ id: "kontakt.g.ulica" })}</h4>
                  <h4 className="mb-2 text-base font-lato text-gray-950 font-medium">{t({ id: "kontakt.g.kod" })}</h4>
                  <div>
                    <a
                      href={`tel:${t({ id: "kontakt.g.tel" })}`}
                      className="text-link"
                      data-track-click
                      data-track-category="info"
                      data-track-component="phone"
                    >
                      {t({ id: "kontakt.g.tel" })}
                    </a>
                  </div>
                  <div>
                    <a
                      href={`tel:+48609925353`}
                      className="text-link"
                      data-track-click
                      data-track-category="info"
                      data-track-component="phone"
                    >
                      +48 609 925 353
                    </a>
                  </div>
                  <div className="mb-2">
                    <a
                      href={`mailto:mostowa@aestheticdent.pl`}
                      className="text-link"
                      data-track-click
                      data-track-category="info"
                      data-track-component="email"
                    >
                      mostowa@aestheticdent.pl
                    </a>
                  </div>
                </address>
                <div className="mb-6">
                  <h4 className="mb-2 font-lato text-gray-950 font-bold">{t({ id: "kontakt.godziny-otwarcia" })}</h4>
                  <p>{t({ id: "kontakt.g.godziny-otwarcia" })}</p>
                </div>
                <div className="mb-6">
                  <h4 className="mb-2 font-lato text-gray-950 font-bold">Parking</h4>
                  <p className="mb-2">{t({ id: "kontakt.g.parking" })}</p>
                  <a
                    href="https://www.google.com/maps/place/NZOZ+Aesthetic+Dent+Stomatologia+Marcin+Tutak/@52.73046,15.2391328,19z/data=!4m5!3m4!1s0x47071fbd7290ccc5:0x1a330a4306e93e8d!8m2!3d52.73046!4d15.23968"
                    className="flex items-center standalone-link"
                  >
                    {t({ id: "kontakt.wskazowki-dojazdu" })} <OutIcon className="ml-1" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="px-m">
            <ContactForm />
          </div>
        </div>
      </main>
    </>
  );
};

export default ContactPage;
