import React from "react";

import AllOnFour from "./AllOnFour";
import Surgery from "./Surgery";

const Specialties = () => {
  return (
    <>
      <Surgery />
      <AllOnFour />
    </>
  );
};

export default Specialties;
