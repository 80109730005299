// extracted by mini-css-extract-plugin
export var article_container = "strefa-pacjenta-module--article_container--11a9a";
export var article_inner = "strefa-pacjenta-module--article_inner--cbf7d";
export var article_inner__header = "strefa-pacjenta-module--article_inner__header--4db6f";
export var bigText = "strefa-pacjenta-module--big-text--80d4c";
export var centered = "strefa-pacjenta-module--centered--84a74";
export var container = "strefa-pacjenta-module--container--9a070";
export var container__inner = "strefa-pacjenta-module--container__inner--bd58f";
export var desktopNone = "strefa-pacjenta-module--desktop-none--a9307";
export var doublePictureRow = "strefa-pacjenta-module--double-picture-row--f22ec";
export var fade = "strefa-pacjenta-module--fade--21ced";
export var fadeUp = "strefa-pacjenta-module--fadeUp--8daba";
export var fadeZoom = "strefa-pacjenta-module--fadeZoom--57e55";
export var inline = "strefa-pacjenta-module--inline--14d44";
export var jsNoscroll = "strefa-pacjenta-module--js-noscroll--1af08";
export var jsVisible = "strefa-pacjenta-module--js-visible--39a4d";
export var list = "strefa-pacjenta-module--list--4e8fc";
export var mobileNone = "strefa-pacjenta-module--mobile-none--94c8c";
export var pictures = "strefa-pacjenta-module--pictures--24b15";
export var richText = "strefa-pacjenta-module--rich-text--23fe6";
export var singlePicture = "strefa-pacjenta-module--single-picture--7575b";
export var singlePictureRow = "strefa-pacjenta-module--single-picture-row--a35c0";
export var text = "strefa-pacjenta-module--text--d3f4c";
export var text__big = "strefa-pacjenta-module--text__big--cdcd6";