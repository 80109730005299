import React from "react";

import useBodyScrollLock from "hooks/useBodyScrollLock";

import { overlay } from "./NavMobile.module.scss";

const Overlay = () => {
  useBodyScrollLock();
  return <div className={overlay} />;
};

export default Overlay;
