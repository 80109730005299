import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Figure from "common/Figure";
import PictureCaption from "components/PictureCaption";
import Button from "common/Button";

import * as styles from "./AllOnFour.module.scss";
import * as specialtiesCss from "../Specialties.module.scss";

const AllOnFour = () => {
  return (
    <div className={styles.layout}>
      <Figure>
        <StaticImage
          src={"../../../images/all-on-four.jpg"}
          className={specialtiesCss.image}
          objectFit="cover"
          objectPosition="center center"
          fadeIn={true}
          layout="constrained"
          alt="Dr Marcin Tutak podczas zabiegu All-on-4"
          width={1440}
        />
        <PictureCaption caption="Dr Marcin Tutak podczas zabiegu All-on-4&trade;" />
      </Figure>
      <div>
        <h2 className={specialtiesCss.title}>
          <span className="nowrap">All-on-4&trade;</span> - rewolucyjna metoda leczenia bezzębia w
          jeden dzień
        </h2>
        <p>
          Współpracujemy z samym twórcą i pomysłodawcą leczenia bezzębia za pomocą 4 implantów, dr
          Paulo Malo. Zabieg wykonujemy na miejscu, w naszym gabinecie w Szczecinie lub Gorzowie, a
          Ty możesz cieszyć się nowym uśmiechem po zaledwie kilku godzinach.
        </p>
        <Button to="/all-on-4/">Dowiedz się więcej</Button>
      </div>
    </div>
  );
};

export default AllOnFour;
