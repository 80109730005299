// extracted by mini-css-extract-plugin
export var appContainer = "page-styles-module--app-container--682fc";
export var appContainerSection = "page-styles-module--app-container-section--5589d";
export var baseSection = "page-styles-module--base-section--bbf57";
export var bgSectionPrimary = "page-styles-module--bg-section-primary--638f4";
export var bigText = "page-styles-module--big-text--05b51";
export var borders = "page-styles-module--borders--7f86d";
export var chevron = "page-styles-module--chevron--556cc";
export var chevronRight = "page-styles-module--chevron-right--5b305 page-styles-module--chevron--556cc";
export var divider = "page-styles-module--divider--8f234";
export var doubleColSection = "page-styles-module--double-col-section--b8f12";
export var faq = "page-styles-module--faq--aeb46";
export var headerSideSection = "page-styles-module--header-side-section--15825";
export var headerTextSection = "page-styles-module--header-text-section--7fc0b";
export var hero = "page-styles-module--hero--d09c0";
export var heroSection = "page-styles-module--hero-section--a8f45";
export var image = "page-styles-module--image--350bc";
export var link = "page-styles-module--link--ff0c2";
export var pictureRowSection = "page-styles-module--picture-row-section--4de3f";
export var richText = "page-styles-module--rich-text--8ab8c";
export var text = "page-styles-module--text--22396";
export var title = "page-styles-module--title--e76d5";