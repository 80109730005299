import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";

import "../styles/map.scss";

const MyMap = ({ center, className }) => {
  const staticMapUrl = `https://maps.googleapis.com/maps/api/staticmap`;
  const scale = `scale=2`;
  const zoom = `zoom=16`;

  return (
    <div className={`map-fluid ${className}`}>
      <FormattedMessage id={`kontakt.${center}`}>
        {(msg) => (
          <img
            className="map"
            src={`${staticMapUrl}?center=${center}&${zoom}&size=600x600&maptype=roadmap&${scale}&markers=%7Ccolor:red%7C${center}&key=${process.env.GATSBY_GOOGLE_API_KEY}`}
            alt={msg}
          />
        )}
      </FormattedMessage>
    </div>
  );
};

MyMap.propTypes = {
  center: PropTypes.string.isRequired,
};

export default MyMap;
