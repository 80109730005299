import React from "react";

import * as styles from "./MainPoints.module.scss";

const MainPoints = () => {
  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>Stomatologia w naszym ujęciu</h2>
      <div className={styles.layout}>
        <div className={styles.item}>
          <h3 className={styles.itemTitle}>Kompleksowa oferta</h3>
          <p>
            Oferujemy specjalistyczne usługi z każdej dziedziny stomatologii - chirurgii
            stomatologicznej, ortodoncji, protetyki i periodontologii.
          </p>
        </div>
        <div className={styles.item}>
          <h3 className={styles.itemTitle}>Laboratorium protetyczne</h3>
          <p>
            Wszystkie prace wykonywane są przez naszych techników w naszej klinice, w ścisłej
            współpracy z lekarzami prowadzącymi.
          </p>
        </div>
        <div className={styles.item}>
          <h3 className={styles.itemTitle}>Międzynarodowa współpraca</h3>
          <p>
            Jako klinika prowadzimy badania naukowe, a nasi lekarze są członkami wielu
            międzynarodowych stowarzyszeń stomatologicznych.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainPoints;
