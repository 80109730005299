import React from "react";

import { dropdown, overlay } from "./NavDesktop.module.scss";

const Dropdown = ({ visible, onOverlayClick, children }) => {
  return (
    <>
      <div
        className={overlay}
        style={{
          display: visible ? "block" : "none",
        }}
        onClick={onOverlayClick}
      />
      <div
        className={dropdown}
        style={{
          display: visible ? "block" : "none",
        }}
        aria-expanded={visible}
      >
        <div>{children}</div>
      </div>
    </>
  );
};

export default Dropdown;
