import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Layout from "components/Layout";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "ortognatyka.deformacje-szczekowo-twarzowe" },
  { key: "ortognatyka.moja-historia" },
  { key: "ortodoncja" },
];

const TypyZabiegowPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO title={formatMessage({ id: "oferta.ortognatyka.typy-zabiegow.seo.title" })} />
      <main>
        <article className={styles.article}>
          <section>
            <div className={styles.text}>
              <h1>{formatMessage({ id: "oferta.ortognatyka.typy-zabiegow.h1" })}</h1>
              <FormattedMessage id="oferta.ortognatyka.typy-zabiegow.p.1">
                {(msg) => (
                  <p>
                    <FormattedMessage id="oferta.ortognatyka.typy-zabiegow.p.1.fw" tagName="b" />
                    {msg}
                  </p>
                )}
              </FormattedMessage>
              <FormattedMessage id="oferta.ortognatyka.typy-zabiegow.p.2">
                {(msg) => (
                  <p>
                    <FormattedMessage id="oferta.ortognatyka.typy-zabiegow.p.2.fw" tagName="b" />
                    {msg}
                  </p>
                )}
              </FormattedMessage>
              <FormattedMessage id="oferta.ortognatyka.typy-zabiegow.p.3">
                {(msg) => (
                  <p>
                    <FormattedMessage id="oferta.ortognatyka.typy-zabiegow.p.3.fw" tagName="b" />
                    {msg}
                  </p>
                )}
              </FormattedMessage>
              <FormattedMessage id="oferta.ortognatyka.typy-zabiegow.p.4">
                {(msg) => (
                  <p>
                    <FormattedMessage id="oferta.ortognatyka.typy-zabiegow.p.4.fw" tagName="b" />
                    {msg}
                  </p>
                )}
              </FormattedMessage>
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default TypyZabiegowPage;
