import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Breadcrumbs from "components/Breadcrumbs";
import LocalizedLink from "../LocalizedLink";
import FooterNav from "./FooterNav";

import * as styles from "./Footer.module.scss";
import OfertaNav from "./OfertaNav";
import paths from "config/paths";

const Footer = ({ pageContext }) => {
  const { formatMessage: t } = useIntl();

  return (
    <footer className={styles.footer}>
      <Breadcrumbs pageContext={pageContext} />
      <div className={styles.layout}>
        <nav className={styles.nav}>
          <section aria-label="Navigation">
            <h3>{t({ id: "footer.menu" })}</h3>
            <FooterNav />
          </section>
          <section aria-label="Additional navigation">
            <h3>Nasze Specjalności</h3>
            <ul className={styles.navList}>
              <li>
                <LocalizedLink to="/all-on-4/" partiallyActive={true} className={styles.navItem}>
                  <span className="nowrap">All-on-4&trade;</span>
                </LocalizedLink>
              </li>
              <li>
                <LocalizedLink to={paths.veneersAndComposites}>Licówki i kompozyty</LocalizedLink>
              </li>
              <li>
                <LocalizedLink to={paths.bruxism}>Bruksizm</LocalizedLink>
              </li>
            </ul>
          </section>
          <section aria-label="Oferta">
            <h3>{t({ id: "footer.oferta" })}</h3>
            <OfertaNav />
          </section>
        </nav>
        <div className={styles.contact}>
          <address className={styles.address}>
            <div>
              <h3>Szczecin</h3>
              <FormattedMessage id="kontakt.w.ulica" tagName="span" />
              <FormattedMessage id="kontakt.w.kod" tagName="span" />
              <FormattedMessage id="kontakt.w.tel">
                {(msg) => (
                  <a href={`tel:${msg}`} data-track-click data-track-category="info" data-track-component="phone">
                    {msg}
                  </a>
                )}
              </FormattedMessage>
              <a
                href={`mailto:wyspianskiego@aestheticdent.pl`}
                data-track-click
                data-track-category="info"
                data-track-component="email"
              >
                wyspianskiego@aestheticdent.pl
              </a>{" "}
            </div>
            <div>
              <FormattedMessage id="kontakt.godziny-otwarcia" tagName="h3" />
              <FormattedMessage id="kontakt.w.godziny-otwarcia" tagName="span" />
            </div>
          </address>
          <address className={styles.address}>
            <div>
              <h3>Gorzów</h3>
              <FormattedMessage id="kontakt.g.ulica" tagName="span" />
              <FormattedMessage id="kontakt.g.kod" tagName="span" />
              <FormattedMessage id="kontakt.g.tel">{(msg) => <a href={`tel:${msg}`}>{msg}</a>}</FormattedMessage>
              <a href={`tel:+48609925353`} data-track-click data-track-category="info" data-track-component="phone">
                +48 609 925 353
              </a>
              <a
                href={`mailto:mostowa@aestheticdent.pl`}
                data-track-click
                data-track-category="info"
                data-track-component="email"
              >
                mostowa@aestheticdent.pl
              </a>
            </div>
            <div>
              <FormattedMessage id="kontakt.godziny-otwarcia" tagName="h3" />
              <FormattedMessage id="kontakt.g.godziny-otwarcia" tagName="span" />
            </div>
          </address>
        </div>
        <section className={styles.bottom}>
          <div>
            <LocalizedLink to="/strefa-pacjenta/polityka-prywatnosci/">
              {t({
                id: "strefa-pacjenta.polityka-prywatnosci",
              })}
            </LocalizedLink>
            <LocalizedLink to="/faq/">FAQ</LocalizedLink>
            <a href="https://pl-pl.facebook.com/AestheticDentSzczecin/">Facebook</a>
          </div>
          © Aesthetic Dent 2023 — Stomatologia Szczecin i Gorzów Wielkopolski: ortodoncja, protetyka, implanty,
          chirurgia stomatologiczna.
          <a href="https://cmaesthetic.pl" className="text-xs">
            Aesthetic Centrum Medyczne - lekarze specjaliści w Gorzowie Wielkopolskim.
          </a>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
