import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import classnames from "classnames";

import Slider from "components/Slider";
import Figure from "common/Figure";
import PictureCaption from "components/PictureCaption";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion";
import SEO from "components/Seo";
import VeneersAndCompositesJsonLd from "components/VeneersAndCompositesJsonLd";

import {
  heroSection,
  hero,
  image,
  bgSectionPrimary,
  headerTextSection,
  headerSideSection,
  baseSection,
  divider,
  appContainer,
  borders,
  title,
  text,
  pictureRowSection,
  doubleColSection,
  faq,
} from "styles/page-styles.module.scss";

const VeneersAndCompositesPage = () => {
  const data = useStaticQuery(query);

  return (
    <>
      <VeneersAndCompositesJsonLd />
      <>
        <SEO
          title="Licówki i kompozyty"
          description="Licówki, kompozyty i bonding zębów. Małoinwazyjne metody stomatologiczne na piękny uśmiech. Licówki w Szczecinie i Gorzówie Wielkopolskim."
        />
        <div className={heroSection}>
          <div className={hero}>
            <div>
              <h1>Licówki i kompozyty</h1>
              <h2>Sedno stomatologii estetycznej i sekret do pięknego uśmiechu.</h2>
            </div>
            <Figure>
              <Img
                fluid={data.hero.childImageSharp.fluid}
                className={image}
                alt="Licówki. Źródło: materiały prasowe Dentsply"
              />
              <PictureCaption caption="Źródło: materiały prasowe Dentsply" />
            </Figure>
          </div>
        </div>
        <article>
          <div className={bgSectionPrimary}>
            <div className={headerTextSection}>
              <p>
                Licówki to najlepsza metoda na rozwiązanie problemów estetycznych w jamie ustnej.
              </p>
              <p>
                Dzięki nim osiągamy efekt idealnego uśmiechu bez znacznej ingerencji w tkankę twardą
                naturalnego uzębienia.
              </p>
            </div>
          </div>
          <section className={baseSection}>
            <div className={headerSideSection}>
              <h2>Czym są licówki?</h2>
              <p>
                Licówki są cienkimi nakładkami nazębnymi wykonanymi najczęściej z porcelany, które
                bardzo dobrze sprawdzają się na zębach przednich. Można je stosować w niektórych
                przypadkach również na zębach przedtrzonowych i trzonowych. Przygotowanie zęba do
                zabiegu jest bezbolesne — dotyczy tylko i wyłącznie zewnętrznej strony zęba.
              </p>
            </div>
          </section>
          <section className={baseSection}>
            <div className={pictureRowSection}>
              <Figure>
                <Img
                  fluid={data.veneers_1.childImageSharp.fluid}
                  alt="Licówka. Żródło: materiały prasowe Dentsply"
                />
                <PictureCaption caption="Licówka. Żródło: materiały prasowe Dentsply" />
              </Figure>
              <Figure>
                <Img
                  fluid={data.veneers_2.childImageSharp.fluid}
                  alt="Licówka. Żródło: materiały prasowe Dentsply"
                />
                <PictureCaption caption="Licówka. Żródło: materiały prasowe Dentsply" />
              </Figure>
            </div>
          </section>
          <div className={divider} />
          <section className={baseSection}>
            <div className={headerSideSection}>
              <h2>Wskazania do zastosowania licówek</h2>
              <div>
                <p>
                  Licówki umożliwiają zmianę koloru zębów, zamaskowanie przebarwień i plam
                  powstałych najczęściej z powodu leczenia tetracyklinami lub fluorozą. Można je
                  również zastosować w przypadku zniszczenia zębów przez próchnicę, które zostały
                  uzupełnione wypełnieniami o różnych odcieniach. Doskonale nadają się do
                  uzupełniania brakujących części zębów spowodowanych starciem lub urazem. Za ich
                  pomocą możemy zmieniać kształt zębów czyli powiększać, wydłużać lub poszerzać.
                  Wykorzystuje się je też do zamykania nieestetycznych szpar między zębami m.in.
                  diastemy.
                </p>
                <p>
                  Leczenie licówkami zębowymi proponujemy osobom w każdym wieku, ponieważ zabieg w
                  niewielkim stopniu ingeruje we własne zęby.
                </p>
              </div>
            </div>
          </section>
          <section className={baseSection}>
            <div className={pictureRowSection}>
              <Figure>
                <Img
                  fluid={data.veneers_g7.childImageSharp.fluid}
                  alt="Wskazania do zastosowania licówek ceramicznych."
                />
                <PictureCaption caption="Wskazania do zastosowania licówek ceramicznych" />
              </Figure>
              <Figure>
                <Img
                  fluid={data.veneers_g5.childImageSharp.fluid}
                  alt="Wskazania do zastosowania licówek ceramicznych."
                />
                <PictureCaption caption="Wskazania do zastosowania licówek ceramicznych" />
              </Figure>
            </div>
          </section>
          <section className={baseSection}>
            <div className={headerSideSection}>
              <h2>Zalety stosowania licówek ceramicznych</h2>
              <div>
                <p>
                  Licówki wykonuje się z materiałów ceramicznych, które są bezpieczne i przede
                  wszystkim bardzo trwałe. Wykorzystanie tych materiałów zapewnia uzyskanie
                  naturalnego i zdrowego uśmiechu.
                </p>
                <p>
                  Niewątpliwą zaletą ich stosowania jest mniejsza ingerencja stomatologiczna niż w
                  przypadku tradycyjnych koron metalowo-ceramicznych lub cyrkonowych. Niewielka
                  redukcja twardych tkanek zęba, wyjątkowa szczelność brzeżna, a także duża
                  odporność na ścieranie powodują, że pacjenci chętnie wybierają tę formę
                  stomatologii estetycznej. Jest to jedna z najbardziej uniwersalnych metod korekty
                  defektów kosmetycznych zębów.
                </p>
              </div>
            </div>
          </section>
          <div className={divider} />
          <section className={baseSection}>
            <div className={headerSideSection}>
              <h2>Bonding zębów — licówka kompozytowa</h2>
              <div>
                <p>
                  Bonding zębów to alternatywna nazwa dla licówki kompozytowej. Bonding wykonuje się
                  w celu przywrócenia złamanym, zniekształconym lub przebarwionym koronom zębów
                  estetycznego wyglądu. Za jego pomocą także możemy zniwelować diastemę, wyrównać
                  przednie zęby lub wizualnie poprawić zgryz.
                </p>
                <p>
                  Planując użycie kompozytu do odbudowy zębów, decydujemy się na leczenie bez
                  szlifowania zębów. To sprawia, że ta metoda jest obecnie bardzo popularnym
                  zabiegiem stomatologii estetycznej i jest zalecana przede wszystkim osobom młodym,
                  ze zdrowymi zębami i pacjentom, którzy życzą sobie jak najmniej inwazyjnej metody
                  leczenia.
                </p>
                <p>
                  Naturalne zęby można pokrywać w całości lub odbudowywać tylko ich uszkodzone
                  fragmenty. Efekt estetyczny bondingu jest znakomity, a zęby pacjenta pozostają w
                  naturalnym stanie.
                </p>
              </div>
            </div>
          </section>
          <section className={baseSection}>
            <div className={headerSideSection}>
              <h2>Czy bonding zębów może być alternatywą dla licówki ceramicznej?</h2>
              <div>
                <p>
                  Obie metody odbudowy zębów są do siebie bardzo podobne, a ich różnice tkwią w
                  detalach i zaleceniach. Dobór metody leczenia zależy od warunków w jamie ustnej
                  pacjenta, jak i jego oczekiwań.
                </p>
                <p>
                  Licówki porcelanowe są zdecydowanie bardziej trwałe niż odbudowa kompozytowa i nie
                  wymagają tak częstych poprawek, przez co polecamy je pacjentom, u których do
                  osiągnięcia estetycznego efektu wymagane jest opracowanie kilku zębów lub nawet
                  całego łuku zębowego. Porcelana nie traci swoich właściwości estetycznych wraz z
                  upływem czasu, nie odbarwia się ani nie matowieje. Inaczej jest w przypadku
                  kompozytów. Decydując się kompozyt należy pamiętać, że materiał może stopniowo
                  tracić swój połysk, a wypełnienie stać się bardziej matowe. Jest to dość ważny
                  czynnik w momencie, gdy fragment zęba zakwalifikowany do rekonstrukcji nie należy
                  do najmniejszych.
                </p>
                <p>
                  Metodę odbudowy licówkami ceramicznymi zaliczamy do stomatologii małoinwazyjnej,
                  jednak w przypadku ich zakładania musimy pamiętać, że niezbędne jest uprzednie
                  spiłowanie zewnętrznej powierzchni zębów. Bonding natomiast może być opracowywany
                  bezpośrednio na zębach pacjenta i jest to całkowicie nieinwazyjna metoda leczenia.
                  Stopień szlifowania zęba pod licówkę zależy w dużej mierze od umiejętności i
                  doświadczenia lekarza protetyka prowadzącego leczenie.
                </p>
                <p>
                  Dodatkową zaletą stosowania odbudowy kompozytowej jest możliwość wykonania usługi
                  w ciągu jednej wizyty w gabinecie, natomiast odbudowy pełnoceramiczne wymagają
                  odpowiedniego przygotowania w pracowni protetycznej i rozłożenia leczenia w
                  czasie.
                </p>
              </div>
            </div>
          </section>
          <section className={baseSection}>
            <div className={headerSideSection}>
              <h2>Charakterystyka kompozytu stomatologicznego</h2>
              <p>
                Pracujemy na najwyższej jakości kompozytach Inspiro, zaprojektowanych przez jednego
                z najlepszych specjalistów w dziedzinie kompozytów - Dietera Dietschi. Materiały te
                cechują się doskonałą polerowalnością, dzięki czemu ich powierzchnia jest bardziej
                szklista i odporna na przebarwienia. Kompozyt dostępny jest w wielu odcieniach, co
                pozwala na idealne dopasowanie jego koloru do zębów pacjenta. Dodatkowo, ten
                materiał doskonale przylega do szkliwa, co pozwala nakładać kompozyt warstwami i
                etapowo kształtować odbudowę.
              </p>
            </div>
          </section>
          <div className={baseSection}>
            <section className={appContainer}>
              <h2>Bonding, licówka pełnoceramiczna — przed i po</h2>
              <Slider>
                <div className={pictureRowSection}>
                  <Figure>
                    <Img
                      fluid={data.veneers_g5.childImageSharp.fluid}
                      alt="Stan przed leczeniem licówkami. Zęby zniszczone przez bruksizm."
                    />
                  </Figure>
                  <Figure>
                    <Img
                      fluid={data.veneers_g6.childImageSharp.fluid}
                      alt="Stan po leczeniu licówkami."
                    />
                  </Figure>
                </div>
                <div className={pictureRowSection}>
                  <Figure>
                    <Img
                      fluid={data.veneers_g1.childImageSharp.fluid}
                      alt="Stan przed leczeniem licówkami."
                    />
                  </Figure>
                  <Figure>
                    <Img
                      fluid={data.veneers_g3.childImageSharp.fluid}
                      alt="Stan po leczeniu licówkami."
                    />
                  </Figure>
                </div>
                <div className={pictureRowSection}>
                  <Figure>
                    <Img
                      fluid={data.veneers_g2.childImageSharp.fluid}
                      alt="Stan przed leczeniem licówkami."
                    />
                  </Figure>
                  <Figure>
                    <Img
                      fluid={data.veneers_g4.childImageSharp.fluid}
                      alt="Stan po leczeniu licówkami."
                    />
                  </Figure>
                </div>
                <div className={pictureRowSection}>
                  <Figure>
                    <Img
                      fluid={data.veneers_g7.childImageSharp.fluid}
                      alt="Stan przed leczeniem licówkami."
                    />
                  </Figure>
                  <Figure>
                    <Img
                      fluid={data.veneers_g8.childImageSharp.fluid}
                      alt="Stan po leczeniu licówkami."
                    />
                  </Figure>
                </div>
              </Slider>
            </section>
          </div>
          <div className={borders}>
            <section className={baseSection}>
              <div className={doubleColSection}>
                <div>
                  <h2>Licówki w Szczecinie i Gorzowie Wielkopolskim</h2>
                  <p>
                    W Aesthetic Dent czeka na Ciebie zespół specjalistów protetyki stomatologicznej.
                    Nasi specjaliści mają ponad 20 lat doświadczenia w stosowaniu licówek i
                    kompozytów w stomatologii estetycznej.
                  </p>
                </div>
                <Figure>
                  <Img
                    fluid={data.team.childImageSharp.fluid}
                    alt="Zespół protetyczny Aesthetic Dent"
                  />
                  <PictureCaption caption="Zespół protetyczny Aesthetic Dent" />
                </Figure>
              </div>
            </section>
          </div>
          <section className={baseSection}>
            <div className={headerSideSection}>
              <h2 className={title}>Często zadawane pytania</h2>
              <div className={classnames(text, faq)}>
                <Accordion>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Czym jest bonding zębów?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Bonding to alternatywna nazwa metody wykonania licówki za pomocą kompozytów.
                        Wykonuje się ją w celu nadania złamanym, zniekształconym lub przebarwionym
                        koronom zębów estetycznego wyglądu.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Czy licówki powinien wykonywać specjalista protetyki?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Dobór koloru licówki, sposób przygotowania zęba a także jej cementowanie
                        jest absolutnie konieczne by zapewnić pacjentowi oczekiwane rezultaty
                        leczenia. Dlatego też, gwarancją spektakularnego efektu i naturalnego
                        wyglądu leczenia z wykorzystaniem licówek pełnoceramicznych jest
                        skorzystanie z usług lekarza posiadającego specjalizację z protetyki.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Ile kosztuje licówka pełnoceramiczna?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Cena wykonania jednej licówki pełnoceramicznej w klinice Aesthetic Dent
                        wynosi od 1.500 do 1.700 zł. Koszt obejmuje badanie wstępne, znieczulenie,
                        obróbkę zęba, wypełnienia, wyciski, wykonanie licówki w naszym laboratorium
                        protetycznym, uzupełnienia tymczasowe, korekty i wizyty kontrolne.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>Od czego zależy kolor licówki?</h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Kolor jest dobierany przez specjalistę uwzględniając preferencje pacjenta.
                        Należy jednak pamiętać, że ostateczny efekt kolorystyczny zależy od wielu
                        czynników. Stopień zmiany koloru opracowywanego zęba zależy od grubości
                        licówki i koloru użytego materiału. Im więcej zęba się oszlifuje tym więcej
                        materiału możemy wykorzystać na zrobienie licówki, a co za tym idzie, tym
                        większą zmianę koloru możemy uzyskać.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading ariaLevel={2}>
                      <h2>
                        Czy martwe zęby są przeciwwskazaniem do leczenia licówkami/kompozytami?
                      </h2>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Obecnie z dużym powodzeniem wykonuje się licówki na zębach po leczeniu
                        kanałowym. Jest to niejednokrotnie pewniejsze i mniej inwazyjne rozwiązanie
                        niż korona protetyczna.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </section>
        </article>
      </>
    </>
  );
};

const query = graphql`
  query {
    hero: file(relativePath: { eq: "licowki-i-kompozyty_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_1: file(relativePath: { eq: "licowki-i-kompozyty_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_2: file(relativePath: { eq: "licowki-i-kompozyty_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_g1: file(relativePath: { eq: "licowki-i-kompozyty_g1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_g2: file(relativePath: { eq: "licowki-i-kompozyty_g2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_g3: file(relativePath: { eq: "licowki-i-kompozyty_g3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_g4: file(relativePath: { eq: "licowki-i-kompozyty_g4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_g5: file(relativePath: { eq: "licowki-i-kompozyty_g5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_g6: file(relativePath: { eq: "licowki-i-kompozyty_g6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_g7: file(relativePath: { eq: "licowki-i-kompozyty_g7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    veneers_g8: file(relativePath: { eq: "licowki-i-kompozyty_g8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    team: file(relativePath: { eq: "prosthetics-team.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default VeneersAndCompositesPage;
