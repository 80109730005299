import "styles/badania.scss";
import "styles/article.scss";

import Breadcrumbs from "components/Breadcrumbs";
// import ArticleHero from 'components/ArticleHero';
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Layout from "components/Layout";
import PictureCaption from "components/PictureCaption";
import SEO from "components/Seo";

const BadaniaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      cert: file(relativePath: { eq: "cert.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 75) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({ id: "badania.seo.title" })}
        description={formatMessage({ id: "badania.seo.description" })}
      />
      <main>
        {/* <ArticleHero title="hello" copy="sip sup sup" image="a" /> */}

        <article className="article">
          <section>
            <h2>{formatMessage({ id: "badania.title" })}</h2>
            <FormattedMessage id="badania.p.1" tagName="p" />
            <FormattedMessage id="badania.p.2" tagName="p" />
            <FormattedMessage id="badania.p.3" tagName="p" />
            <div className="results">
              <FormattedMessage id="badania.wyniki" tagName="p" />
              <a className="single" href="https://www.ncbi.nlm.nih.gov/pubmed/25418606">
                Randomized controlled multicentre study comparing short dental implants (6 mm)
                versus longer dental implants (11-15 mm) in combination with sinus floor elevation
                procedures. Part 1: demographics and patient-reported outcomes at 1 year of loading.
              </a>
              <a className="single" href="https://www.ncbi.nlm.nih.gov/pubmed/26425812">
                Randomized controlled multicenter study comparing short dental implants (6 mm)
                versus longer dental implants (11-15 mm) in combination with sinus floor elevation
                procedures. Part 2: clinical and radiographic outcomes at 1 year of loading.
              </a>
              <a className="single" href="https://www.ncbi.nlm.nih.gov/pubmed/28081288">
                Short dental implants (6 mm) versus long dental implants (11-15 mm) in combination
                with sinus floor elevation procedures: 3-year results from a multicentre,
                randomized, controlled clinical trial.
              </a>
              <a className="single" href="https://www.ncbi.nlm.nih.gov/pubmed/30341961">
                Randomized controlled multicentre study comparing short dental implants (6 mm)
                versus longer dental implants (11-15 mm) in combination with sinus floor elevation
                procedures: 5-Year data.
              </a>
              <p>
                {formatMessage({
                  id: "badania.certyfikat",
                })}
              </p>
              <figure className="picture">
                <Img fluid={data.cert.childImageSharp.fluid} />
                <PictureCaption
                  caption={formatMessage({
                    id: "badania.certyfikat.pictureCaption",
                  })}
                />
              </figure>
            </div>
          </section>
        </article>
      </main>
    </>
  );
};

export default BadaniaPage;
