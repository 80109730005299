import React from "react";
import { useIntl } from "react-intl";

import LocalizedLink from "../LocalizedLink";

import * as styles from "./Footer.module.scss";

const navItems = [
  { name: 'aktualnosci' },
  { name: `o-nas` },
  { name: `zespol` },
  { name: `dzialalnosc-naukowa` },
  { name: `strefa-pacjenta` },
  { name: `cennik` },
  { name: `kontakt` },
];

const FooterNav = () => {
  const { formatMessage } = useIntl();

  return (
    <ul className={styles.navList}>
      {navItems.map(({ name }) => {
        return (
          <li key={name}>
            <LocalizedLink to={`/${name}/`}>{formatMessage({ id: `nav.${name}` })}</LocalizedLink>
          </li>
        );
      })}
    </ul>
  );
};

export default FooterNav;
