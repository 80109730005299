import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { useIntl } from "react-intl";

import paths from "config/paths";
import PictureCaption from "components/PictureCaption";
import LocalizedLink from "components/LocalizedLink";
import Services from "common/Services";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel } from "components/Accordion";
import SEO from "components/Seo";
import EndodonticsJsonLd from "components/EndodonticsJsonLd";
import { range } from "utils/range";

import {
  wrapper,
  inner,
  article,
  faqSection,
  verticalPictureSection,
  verticalImage,
} from "styles/offer-styles.module.scss";

const services = [
  { key: "stomatologia-zachowawcza" },
  { key: "chirurgia-stomatologiczna" },
  { key: "profilaktyka" },
  { key: "radiologia" },
];

const ENDODONTICS_FAQ = 9;

const EndodoncjaPage = () => {
  const { formatMessage: t } = useIntl();

  const data = useStaticQuery(query);

  return (
    <>
      <SEO
        title={t({ id: "oferta.endodoncja.seo.title" })}
        description={t({ id: "oferta.endodoncja.seo.description" })}
      />
      <EndodonticsJsonLd />
      <main className={wrapper}>
        <div className={inner}>
          <article className={article}>
            <h1>{t({ id: "oferta.endodoncja.h1" })}</h1>
            <p>
              Endodoncja jest dziedziną stomatologii zajmującą się leczeniem chorób miazgi zębowej i tkanek
              okołowierzchołkowych. Leczenie endodontyczne czyli leczenie kanałowe zęba jest w wielu przypadkach jedynym
              skutecznym sposobem uratowania zęba i uniknięcia ekstrakcji. Postęp technologiczny powoduje, że endodoncja
              jest dziś bardzo dynamicznie rozwijającą się dziedziną stomatologii. Dawno minęły czasy tzw. zatruwania
              zęba przed leczeniem kanałowym. Opracowano wiele różnorodnych technik leczenia zębów z wykorzystaniem
              różnorodnego sprzętu takiego jak mikroskopy, endometry, aparaty RTG o niskim poziomie promieniowania, oraz
              odpowiednie końcówki do opracowywania kanałów.
            </p>
            <h2>Dlaczego występuje konieczność leczenia kanałowego?</h2>
            <p>
              Potrzeba leczenia kanałowego jest spowodowana zapaleniem miazgi, które zwykle powstaje z powodu
              nieleczonej próchnicy. Może być też konieczne z powodu uszkodzenia mechanicznego, głębokiego ubytku czy
              kilkukrotnego leczenia zęba. Rozwój infekcji tkanki objawia się bólem zakażonego zęba, nasilającym się
              podczas spożywania gorących lub zimnych pokarmów. Czasami leczenia kanałowego należy się podjąć przed
              niektórymi zabiegami protetycznymi.
            </p>
            <div className={verticalPictureSection}>
              <div>
                <Img
                  fixed={data.endo_3.childImageSharp.fixed}
                  alt="Złamany ząb — konieczność leczenia kanałowego. RTG zęba."
                  className={verticalImage}
                />
                <PictureCaption caption="Złamany ząb — konieczność leczenia kanałowego." />
              </div>
              <div>
                <Img
                  fixed={data.endo_4.childImageSharp.fixed}
                  alt="Ząb po leczeniu kanałowym, z odbudową protetyczną. RTG zęba."
                  className={verticalImage}
                />
                <PictureCaption caption="Ząb po leczeniu kanałowym, z odbudową protetyczną." />
              </div>
            </div>
            <h2>Powtórne leczenie endodontyczne (reendo)</h2>
            <p>
              Ponownego leczenia kanałowego należy dokonać, gdy kanały korzeniowe są niedopełnione, występują zmiany w
              wierzchołku korzenia, bądź pacjent odczuwa ból zęba po jego wcześniejszym leczeniu endodontycznym.
            </p>
            <h2>Przebieg leczenia kanałowego zęba</h2>
            <p>
              Leczenie rozpoczynamy od wykonania zdjęcia rentgenowskiego, co pozwala na wgląd w wielkość zęba i
              określenie rozległości stanu zapalnego. Po diagnostyce podajemy pacjentowi znieczulenie miejscowe, aby
              leczenie było bezbolesne. Lekarz przy pomocy mikroskopu endodontycznego, endometru i specjalistycznych
              narzędzi usuwa zainfekowaną lub obumarłą tkankę zęba, następnie dokładnie oczyszcza, dezynfekuje wszystkie
              kanały i wypełnienia je odpowiednimi materiałami. Po uszczelnieniu kanałów następuje etap odbudowy korony
              zęba. Ze względu na znaczne zniszczenie twardych tkanek zęba wymaga on wzmocnienia w postaci wkładu z
              włókna szklanego przed odbudową materiałem kompozytowym lub koroną protetyczną. Nieodpowiednio odbudowana
              korona zęba podatna jest na pęknięcia, co może grozić koniecznością usunięcia zęba. Po zakończeniu
              leczenia, wskazane są regularne wizyty kontrolne, na których wykonywane są zdjęcia RTG zęba.
            </p>
            <div className={verticalPictureSection}>
              <div>
                <Img
                  fixed={data.endo_1.childImageSharp.fixed}
                  alt="Ząb przed leczeniem kanałowym. RTG zęba."
                  className={verticalImage}
                />
                <PictureCaption caption="Ząb przed leczeniem kanałowym." />
              </div>
              <div>
                <Img
                  fixed={data.endo_2.childImageSharp.fixed}
                  alt="Ząb po leczeniu kanałowym. Widoczne opracowane kanały zęba. RTG zęba."
                  className={verticalImage}
                />
                <PictureCaption caption="Ząb po leczeniu kanałowym. Widoczne opracowane kanały zęba." />
              </div>
            </div>
            <p>
              W naszych klinikach stomatologicznych w Szczecinie i Gorzowie Wielkopolskim leczenie kanałowe wykonujemy
              pod mikroskopem - jest to najskuteczniejsza forma leczenia, dzięki zwiększonej precyzji wykonania zabiegu.
              Dzięki mikroskopowi lekarz dentysta potrafi dostrzec wszystkie ujścia kanałów, co pozwala na ich dokładne
              wyczyszczenie, udrożnienie i wypełnienie. sPo zatosowaniu tej techniki leczenia nasze zęby mogą służyć nam
              jeszcze przez wiele lat. Leczenie zęba pod mikroskopem często może być wykonane na jednej wizycie,
              niektóre skomplikowane przypadki wymagają większej ilości wizyt.
            </p>
            <p>
              Bezpośrednio po zabiegu i przez kilka kolejnych dni ząb może być bardziej wrażliwy. Zazwyczaj jednak
              usunięcie zainfekowanej miazgi powoduje większą ulgę w porównaniu z dyskomfortem wywołanym nadwrażliwością
              zęba po leczeniu. Ogólnodostępne środki przeciwbólowe łagodzą dolegliwości.
            </p>
            <hr />
            <h2>Ile kosztuje leczenie kanałowe?</h2>
            <p>
              Stomatologia mikroskopowa jest dziedziną niezwykle trudną i wymagającą dużego doświadczenia lekarza jak i
              dużej precyzji w przeprowadzaniu zabiegu. Cena leczenia kanałowego pod mikroskopem w gabinetach Aesthetic
              Dent w Szczecinie i Gorzowie Wielkopolskim zaczyna się od 700 zł. Koszt leczenia kanałowego obejmuje
              znieczulenie, opracowanie mechaniczno-chemiczne kanału oraz jego wypełnienie. Więcej informacji jest
              dostępnych w cenniku Aesthetic Dent. Więcej informacji jest dostępnych w cenniku Aesthetic Dent. Zobacz{" "}
              <LocalizedLink to={paths.pricing}>cennik naszej kliniki</LocalizedLink>.
            </p>
          </article>
          <article className={article}>
            <h2>Pytania i odpowiedzi</h2>
            <section className={faqSection}>
              <Accordion>
                {range(1, ENDODONTICS_FAQ).map((i) => (
                  <AccordionItem key={i}>
                    <AccordionItemHeading ariaLevel={3}>
                      <h3>{t({ id: `faq.endodoncja.pytanie.${i}` })}</h3>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>{t({ id: `faq.endodoncja.odpowiedz.${i}` })}</p>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </section>
          </article>
        </div>
        <Services services={services} />
      </main>
    </>
  );
};

const query = graphql`
  query {
    endo_1: file(relativePath: { eq: "oferta/endodoncja_1.jpg" }) {
      childImageSharp {
        fixed(height: 400, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    endo_2: file(relativePath: { eq: "oferta/endodoncja_2.jpg" }) {
      childImageSharp {
        fixed(height: 400, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    endo_3: file(relativePath: { eq: "oferta/endodoncja_3.jpg" }) {
      childImageSharp {
        fixed(height: 400, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    endo_4: file(relativePath: { eq: "oferta/endodoncja_4.jpg" }) {
      childImageSharp {
        fixed(height: 400, quality: 80) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;

export default EndodoncjaPage;
