import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { range } from "utils/range";

import Services from "common/Services/Services";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [{ key: "ortognatyka" }, { key: "protetyka" }, { key: "stomatologia-estetyczna" }];

const OrtodoncjaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "oferta/ortodoncja-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 50) {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      image2: file(relativePath: { eq: "oferta/ortodoncja-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 50) {
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

  const { formatMessage } = useIntl();

  return (
    <>
      <SEO title={formatMessage({ id: "oferta.ortodoncja.seo.title" })} />
      <main>
        <article className={styles.article}>
          <section>
            <div className={styles.text}>
              <h1>{formatMessage({ id: "oferta.ortodoncja.h1" })}</h1>
              <FormattedMessage id="oferta.ortodoncja.p.1" tagName="p" />
              <FormattedMessage id="oferta.ortodoncja.p.2" tagName="p" />
            </div>
            <div className={styles.text}>
              <FormattedMessage id="oferta.ortodoncja.aparaty.stale.h2" tagName="h3" />
              <FormattedMessage id="oferta.ortodoncja.aparaty.stale.p" tagName="p" />
            </div>
            <div className={styles.text}>
              <FormattedMessage id="oferta.ortodoncja.aparaty.ruchome.h2" tagName="h3" />
              <FormattedMessage id="oferta.ortodoncja.aparaty.ruchome.p" tagName="p" />
            </div>
            <div className={styles.text}>
              <FormattedMessage id="oferta.ortodoncja.aparaty.invisalign.h2" tagName="h3" />
              <FormattedMessage id="oferta.ortodoncja.aparaty.invisalign.p.1" tagName="p" />
              <FormattedMessage id="oferta.ortodoncja.aparaty.invisalign.p.2" tagName="p" />
            </div>
          </section>
          <section>
            <div className={styles.text}>
              <FormattedMessage id="oferta.ortodoncja.etapy.h2" tagName="h3" />
              <ol className={styles.ordered__special}>
                {range(1, 7).map((i) => (
                  <FormattedMessage id={`oferta.ortodoncja.etapy.${i}`}>
                    {(msg) => (
                      <li>
                        {msg}
                        <FormattedMessage id={`oferta.ortodoncja.etapy.${i}.p`} tagName="p" />
                      </li>
                    )}
                  </FormattedMessage>
                ))}
              </ol>
            </div>
          </section>
          <section>
            <div className={styles.text}>
              <FormattedMessage id="oferta.ortodoncja.leczenie-retencyjne" tagName="h3" />
              <FormattedMessage id="oferta.ortodoncja.leczenie-retencyjne.p.1" tagName="p" />
            </div>
          </section>
        </article>
        <article className={styles.article}>
          <section>
            <h2>FAQ</h2>
            <Accordion>
              {range(1, 12).map((i) => (
                <AccordionItem>
                  <AccordionItemHeading ariaLevel={2}>
                    <FormattedMessage id={`faq.ortodoncja.pytanie.${i}`} tagName="h3" />
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <FormattedMessage id={`faq.ortodoncja.odpowiedz.${i}`} tagName="p" />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default OrtodoncjaPage;
