import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "../styles/accordion.scss";

const MyAccordion = ({ children }) => (
  <Accordion allowMultipleExpanded allowZeroExpanded>
    {children}
  </Accordion>
);

const MyAccordionItemHeading = ({ ariaLevel, children }) => (
  <AccordionItemHeading aria-level={ariaLevel}>
    <AccordionItemButton>
      {children}
      <div className="accordion__button__arrow-wrapper">
        <div className="accordion__button__arrow-wrapper__arrow" />
      </div>
    </AccordionItemButton>
  </AccordionItemHeading>
);

export { MyAccordion as Accordion, AccordionItemPanel, AccordionItem, MyAccordionItemHeading as AccordionItemHeading };
