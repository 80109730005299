import React from "react";
import PropTypes from "prop-types";

import * as styles from "./Figure.module.scss";

const Figure = ({ flow, children }) => {
  return <figure className={flow ? styles.figure__flow : styles.figure}>{children}</figure>;
};

Figure.propTypes = {
  flow: PropTypes.bool,
  children: PropTypes.any,
};

export default Figure;
