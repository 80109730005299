import React from "react";

import Footer from "./Footer";
import Header from "./Header";
import TopBar from "./TopBar";

import { container } from "../styles/layout.module.scss";

const Layout = ({ children }) => {

  // @TODO: this is wonky
  const { pageContext = {} } = children?.props ?? {};

  return (
    <>
      <div className={container} id="layout">
        <TopBar />
        <Header />
        {/* main */}
        {children}
        {/* end main */}
        <Footer pageContext={pageContext} />
      </div>
    </>
  );
};

export default Layout;
