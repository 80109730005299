exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-aktualnosci-strapi-news-post-title-js": () => import("./../../../src/pages/aktualnosci/{strapiNewsPost.title}.js" /* webpackChunkName: "component---src-pages-aktualnosci-strapi-news-post-title-js" */),
  "component---src-pages-all-on-4-js": () => import("./../../../src/pages/all-on-4.js" /* webpackChunkName: "component---src-pages-all-on-4-js" */),
  "component---src-pages-bruksizm-js": () => import("./../../../src/pages/bruksizm.js" /* webpackChunkName: "component---src-pages-bruksizm-js" */),
  "component---src-pages-cennik-js": () => import("./../../../src/pages/cennik.js" /* webpackChunkName: "component---src-pages-cennik-js" */),
  "component---src-pages-chirurgia-stomatologiczna-js": () => import("./../../../src/pages/chirurgia-stomatologiczna.js" /* webpackChunkName: "component---src-pages-chirurgia-stomatologiczna-js" */),
  "component---src-pages-dzialalnosc-naukowa-badania-js": () => import("./../../../src/pages/dzialalnosc-naukowa/badania.js" /* webpackChunkName: "component---src-pages-dzialalnosc-naukowa-badania-js" */),
  "component---src-pages-dzialalnosc-naukowa-js": () => import("./../../../src/pages/dzialalnosc-naukowa.js" /* webpackChunkName: "component---src-pages-dzialalnosc-naukowa-js" */),
  "component---src-pages-endodoncja-js": () => import("./../../../src/pages/endodoncja.js" /* webpackChunkName: "component---src-pages-endodoncja-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-higienizacja-js": () => import("./../../../src/pages/higienizacja.js" /* webpackChunkName: "component---src-pages-higienizacja-js" */),
  "component---src-pages-implanty-js": () => import("./../../../src/pages/implanty.js" /* webpackChunkName: "component---src-pages-implanty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-licowki-i-kompozyty-js": () => import("./../../../src/pages/licowki-i-kompozyty.js" /* webpackChunkName: "component---src-pages-licowki-i-kompozyty-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-ortodoncja-js": () => import("./../../../src/pages/ortodoncja.js" /* webpackChunkName: "component---src-pages-ortodoncja-js" */),
  "component---src-pages-ortognatyka-deformacje-szczekowo-twarzowe-js": () => import("./../../../src/pages/ortognatyka/deformacje-szczekowo-twarzowe.js" /* webpackChunkName: "component---src-pages-ortognatyka-deformacje-szczekowo-twarzowe-js" */),
  "component---src-pages-ortognatyka-js": () => import("./../../../src/pages/ortognatyka.js" /* webpackChunkName: "component---src-pages-ortognatyka-js" */),
  "component---src-pages-ortognatyka-moja-historia-js": () => import("./../../../src/pages/ortognatyka/moja-historia.js" /* webpackChunkName: "component---src-pages-ortognatyka-moja-historia-js" */),
  "component---src-pages-ortognatyka-typy-zabiegow-js": () => import("./../../../src/pages/ortognatyka/typy-zabiegow.js" /* webpackChunkName: "component---src-pages-ortognatyka-typy-zabiegow-js" */),
  "component---src-pages-profilaktyka-js": () => import("./../../../src/pages/profilaktyka.js" /* webpackChunkName: "component---src-pages-profilaktyka-js" */),
  "component---src-pages-protetyka-js": () => import("./../../../src/pages/protetyka.js" /* webpackChunkName: "component---src-pages-protetyka-js" */),
  "component---src-pages-protetyka-korony-js": () => import("./../../../src/pages/protetyka/korony.js" /* webpackChunkName: "component---src-pages-protetyka-korony-js" */),
  "component---src-pages-protetyka-stomatologia-3-d-js": () => import("./../../../src/pages/protetyka/stomatologia-3d.js" /* webpackChunkName: "component---src-pages-protetyka-stomatologia-3-d-js" */),
  "component---src-pages-radiologia-js": () => import("./../../../src/pages/radiologia.js" /* webpackChunkName: "component---src-pages-radiologia-js" */),
  "component---src-pages-stomatologia-estetyczna-js": () => import("./../../../src/pages/stomatologia-estetyczna.js" /* webpackChunkName: "component---src-pages-stomatologia-estetyczna-js" */),
  "component---src-pages-stomatologia-maloinwazyjna-js": () => import("./../../../src/pages/stomatologia-maloinwazyjna.js" /* webpackChunkName: "component---src-pages-stomatologia-maloinwazyjna-js" */),
  "component---src-pages-stomatologia-zachowawcza-js": () => import("./../../../src/pages/stomatologia-zachowawcza.js" /* webpackChunkName: "component---src-pages-stomatologia-zachowawcza-js" */),
  "component---src-pages-strefa-pacjenta-js": () => import("./../../../src/pages/strefa-pacjenta.js" /* webpackChunkName: "component---src-pages-strefa-pacjenta-js" */),
  "component---src-pages-strefa-pacjenta-strapi-patients-section-title-js": () => import("./../../../src/pages/strefa-pacjenta/{strapiPatientsSection.title}.js" /* webpackChunkName: "component---src-pages-strefa-pacjenta-strapi-patients-section-title-js" */),
  "component---src-pages-zespol-js": () => import("./../../../src/pages/zespol.js" /* webpackChunkName: "component---src-pages-zespol-js" */),
  "component---src-pages-zespol-strapi-team-member-name-js": () => import("./../../../src/pages/zespol/{strapiTeamMember.name}.js" /* webpackChunkName: "component---src-pages-zespol-strapi-team-member-name-js" */)
}

