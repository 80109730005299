import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import Layout from "components/Layout";
import Figure from "common/Figure";
import SEO from "components/Seo";
import Slider from "components/Slider";
import LocalizedLink from "components/LocalizedLink";
import paths from "config/paths";

import {
  heroSection,
  hero,
  bgSectionPrimary,
  headerSideSection,
  headerTextSection,
  baseSection,
  appContainer,
  pictureRowSection,
  image,
} from "styles/page-styles.module.scss";

const BruksizmPage = () => {
  const data = useStaticQuery(query);

  return (
    <>
      <SEO
        title="Bruksizm"
        description="Starte zęby — problem dotykający pacjentów w każdym wieku. Zobacz efekty rekonstrucji startego uśmiechu."
      />
      <main>
        <div className={heroSection}>
          <div className={hero}>
            <div>
              <h1>Bruksizm</h1>
              <h2>Starte zęby to problem dotykający pacjentów w każdym wieku.</h2>
            </div>
            <Figure>
              <Img fluid={data.hero.childImageSharp.fluid} className={image} />
            </Figure>
          </div>
        </div>
        <article>
          <div className={bgSectionPrimary}>
            <div className={headerTextSection}>
              <p>
                Starcie zębów dotyczy coraz większej grupy pacjentów, i co istotniejsze, coraz
                młodszej. Przyczyny jego powstawania mogą być różne. Występuje nie tylko na tle
                nerwowym, skutkującym zgrzytaniem i zaciskaniem zębów w nocy, ale także jego
                powstawaniu sprzyja dieta bogata w słodkie napoje energetyzujące.
              </p>
              <p>
                Objawia się zmianą kształtu i koloru zębów, kruszeniem brzegu siecznego, często
                wypadającymi wypełnieniami, a także nadwrażliwością na czynniki termiczne.
                Nieleczony, może prowadzić do poważniejszych powikłań.
              </p>
            </div>
          </div>
          <section className={baseSection}>
            <div className={headerSideSection}>
              <h2>Odbudowa zębów zniszczonych przez bruksizm</h2>
              <div>
                <p>
                  Obecnie mamy wiele różnych możliwości pozwalających na rekonstrukcję utraconych
                  fragmentów zęba. Najczęściej wykorzystujemy w tym celu wysokiej klasy kompozyty o
                  małym stopniu ścieralności (Inspiro). Odbudowa kompozytowa czyli bonding zębów, w
                  połączeniu z wykonaniem odpowiedniej szyny relaksacyjnej, aby zapobiec dalszemu
                  niszczeniu zębów, zazwyczaj jest wystarczająca do pozbycia się problemu bruksizmu.
                </p>
                <p>
                  W przypadkach znacznego starcia zębów odtwarzamy zęby nakładami kompozytowymi i
                  licówkami ceramicznymi, a na bardzo zniszczonych zębach możemy wykonać odbudowę za
                  pomocą koron pełnoceramicznych zapewniając przede wszystkim piękny uśmiech i
                  prawidłowo funkcjonujące uzębienie. Więcej informacji na temat kompozytów i
                  licówek ceramicznych, ich zastosowaniu, wadach i zaletach, znajdziesz{" "}
                  <LocalizedLink to={paths.veneersAndComposites}>tutaj</LocalizedLink>.
                </p>
              </div>
            </div>
          </section>
          <div className={baseSection}>
            <section className={appContainer}>
              <h2>Leczenie bruksizmu — efekty</h2>
              <Slider>
                <div className={pictureRowSection}>
                  <Figure>
                    <Img
                      fluid={data.bruxism_1.childImageSharp.fluid}
                      alt="Stan przed leczeniem licówkami."
                    />
                  </Figure>
                  <Figure>
                    <Img
                      fluid={data.bruxism_2.childImageSharp.fluid}
                      alt="Stan po leczeniu licówkami."
                    />
                  </Figure>
                </div>
                <div className={pictureRowSection}>
                  <Figure>
                    <Img
                      fluid={data.bruxism_3.childImageSharp.fluid}
                      alt="Stan przed leczeniem licówkami."
                    />
                  </Figure>
                  <Figure>
                    <Img
                      fluid={data.bruxism_4.childImageSharp.fluid}
                      alt="Stan po leczeniu licówkami."
                    />
                  </Figure>
                </div>
              </Slider>
            </section>
          </div>
        </article>
      </main>
    </>
  );
};

const query = graphql`
  query {
    hero: file(relativePath: { eq: "bruksizm-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bruxism_1: file(relativePath: { eq: "bruksizm_g1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bruxism_2: file(relativePath: { eq: "bruksizm_g2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bruxism_3: file(relativePath: { eq: "licowki-i-kompozyty_g5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bruxism_4: file(relativePath: { eq: "licowki-i-kompozyty_g6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default BruksizmPage;
