import React from "react";
import { useIntl } from "react-intl";

import JsonLd from "common/JsonLd";

const IndexJsonLd = () => {
  const { formatMessage: t } = useIntl();

  return (
    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "WebSite",
            url: "https://aestheticdent.pl",
            name: "Aesthetic Dent",
            author: "Aesthetic Dent",
            description:
              "Prowadzimy kompleksowe leczenie stomatologiczne. Stomatologia estetyczna, protetyka, ortodoncja, implanty - wszystkie usługi dostępne w jednym miejscu.",
          },
          {
            "@type": "Dentist",
            name: "NZOZ Aesthetic Dent Stomatologia Marcin Tutak",
            url: "https://aestheticdent.pl",
            description:
              "Prowadzimy kompleksowe leczenie stomatologiczne. Stomatologia estetyczna, protetyka, ortodoncja, implanty - wszystkie usługi dostępne w jednym miejscu.",
            logo: "/aestheticdent_logo.svg",
            isAcceptingNewPatients: true,
            paymentAccepted: "Cash, Credit Card",
            priceRange: "$$",
            telephone: ["+48918172722", "+48957351113", "+48609925353"],
            image: "/team.png",
            address: {
              "@type": "PostalAddress",
              addressCountry: "Poland",
              addressLocality: "Szczecin",
              addressRegion: "Zachodniopomorskie",
              postalCode: "70-497",
              streetAddress: "Stanisława Wyspiańskiego 7",
            },
            location: [
              {
                "@type": "PostalAddress",
                addressCountry: "Poland",
                addressLocality: "Szczecin",
                addressRegion: "Zachodniopomorskie",
                postalCode: "70-497",
                streetAddress: "Stanisława Wyspiańskiego 7",
              },
              {
                "@type": "PostalAddress",
                addressCountry: "Poland",
                addressLocality: "Szczecin",
                addressRegion: "Zachodniopomorskie",
                postalCode: "71-220",
                streetAddress: "Niedziałkowskiego 47/3",
              },
              {
                "@type": "PostalAddress",
                addressCountry: "Poland",
                addressLocality: "Gorzów Wielkopolski",
                addressRegion: "Lubuskie",
                postalCode: "66-400",
                streetAddress: "Mostowa 12",
              },
            ],
            sameAs: ["https://www.facebook.com/AestheticDentSzczecin/"],
          },
        ],
      }}
    </JsonLd>
  );
};

export default IndexJsonLd;
