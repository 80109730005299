import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import SEO from "components/Seo";
import { range } from "utils/range";
import * as styles from "styles/ofertaDetail.module.scss";

const ProfilaktykaPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({ id: "oferta.profilaktyka.h1" })}
        description={formatMessage({
          id: "oferta.profilaktyka.seo.description ",
        })}
      />
      <main>
        <article className={styles.article}>
          <section>
            <div className={styles.text}>
              <h1>{formatMessage({ id: "oferta.profilaktyka.h1" })}</h1>
              <FormattedMessage id="oferta.profilaktyka.p.1" tagName="p" />
              <FormattedMessage id="oferta.profilaktyka.zabiegi" tagName="p" />
              <ul>
                {range(1, 10).map((i) => (
                  <FormattedMessage id={`oferta.profilaktyka.zabiegi.li.${i}`} tagName="li" />
                ))}
              </ul>
            </div>
          </section>
        </article>
      </main>
    </>
  );
};

export default ProfilaktykaPage;
