import React from "react";
import { createContext, useContext } from "react";
import Modal from "../components/Modal/Modal";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

export const PresentationContext = createContext({
  location: '',
});

export const usePresentationContext = () => {
  const { location} = useContext(PresentationContext);
  const loc = useLocation();

  const { modal } = location?.state ?? {};

  if (!modal) {
    return { PresentationComponent: ({ children }) => <>{children}</> };
  }

  location.state.modal = false;

  const onClose = () => {
    navigate(-1, { state: { shouldPreventScroll: true } });
  }

  const PresentationComponent = ({ children }) => <Modal open={modal} onClose={onClose}>{children}</Modal>;

  return { PresentationComponent };
}