import React from "react";
import classNames from "classnames";

import * as styles from "./PageIntro.module.scss";

const PageIntro = ({ title, subtitle, centered }) => {
  return (
    <section className={styles.wrapper}>
      <div className={classNames(styles.pageIntro, centered && styles.centered)}>
        <h1 className={styles.title}>{title}</h1>
        <span className={styles.subtitle}>{subtitle}</span>
      </div>
    </section>
  );
};

export default PageIntro;
