import { useLocation } from "@reach/router";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useIntl, FormattedMessage } from "react-intl";

import LocalizedLink from "components/LocalizedLink";
import OutIcon from "images/svg/out";
import paths from "config/paths";

import Dropdown from "./Dropdown";
import * as styles from "./NavDesktop.module.scss";

const services = [
  { key: "protetyka" },
  { key: "ortodoncja" },
  { key: "ortognatyka" },
  { key: "implanty" },
  { key: "chirurgia-stomatologiczna" },
  { key: "endodoncja" },
  { key: "stomatologia-estetyczna" },
  { key: "stomatologia-zachowawcza" },
  { key: "stomatologia-maloinwazyjna" },
  { key: "radiologia" },
  { key: "profilaktyka" },
  { key: "higienizacja" },
];

const ServicesDropdown = () => {
  const { formatMessage: t } = useIntl();

  return (
    <div className={styles.offer}>
      <div className={styles.specialties}>
        <h2>Nasze specjalności</h2>
        <div>
          <LocalizedLink to={paths.allOn4} className={styles.link}>
            All-on-4&trade;
          </LocalizedLink>
        </div>
        <div>
          <LocalizedLink to={paths.veneersAndComposites} className={styles.link}>
            Licówki i kompozyty
          </LocalizedLink>
        </div>
        <div>
          <LocalizedLink to={paths.bruxism} className={styles.link}>
            Bruksizm
          </LocalizedLink>
        </div>
      </div>
      <div className={styles.services}>
        <h2>Oferta</h2>
        <div>
          {services.map((service) => {
            return (
              <div key={service.key}>
                <LocalizedLink to={`/${service.key}/`} className={styles.link}>
                  {t({ id: `breadcrumb.${service.key}` })}
                </LocalizedLink>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const AboutDropdown = () => (
  <>
    <div className={styles.dropdown__about}>
      <div>
        <FormattedMessage id="o-nas.h1">
          {(msg) => (
            <LocalizedLink to="/o-nas/" className="link">
              {msg}
            </LocalizedLink>
          )}
        </FormattedMessage>
        <p className={styles.about__text}>
          Dowiedz się więcej o naszych wartościach, misji i gabinecie.
        </p>
      </div>
      <div>
        <FormattedMessage id="zespol.h1">
          {(msg) => (
            <LocalizedLink to="/zespol/" className="link">
              {msg}
            </LocalizedLink>
          )}
        </FormattedMessage>
        <p className={styles.about__text}>
          Poznaj członków naszego doświadczonego zespołu lekarzy.
        </p>
      </div>
      <div>
        <FormattedMessage id="dzialalnosc-naukowa.h1">
          {(msg) => (
            <LocalizedLink to="/dzialalnosc-naukowa/" className="link">
              {msg}
            </LocalizedLink>
          )}
        </FormattedMessage>
        <p className={styles.about__text}>
          Dowiedz się więcej o działalnosci naukowej naszego zespołu, publikacjach i prowadzonych
          badaniach klinicznych.
        </p>
      </div>
    </div>
  </>
);

/* ====================================================================================== */

const initialState = {
  services: false,
  about: false,
};

const NavDesktop = () => {
  const { pathname } = useLocation();
  const { formatMessage: t } = useIntl();

  const [visible, setVisible] = useState(initialState);

  const aboutActive = () => {
    if (visible.about) {
      return true;
    }
    return pathname.match("o-nas|zespol|dzialalnosc-naukowa");
  };

  const servicesActive = () => {
    if (visible.services) {
      return true;
    }
    return pathname.match(
      `protetyka|ortodoncja|ortognatyka|implanty|chirurgia-stomatologiczna|stomatologia-estetyczna|stomatologia-zachowawcza|endodoncja|radiologia|stomatologia-maloinwazyjna|profilaktyka|all-on-4/|bruksizm|licowki-i-kompozyty|higienizacja`,
    );
  };

  const newsActive = () => {
    return pathname.match("aktualnosci");
  }

  const setServicesVisible = (e) => {
    e.preventDefault();
    setVisible({ ...initialState, services: !visible.services });
  };

  const setAboutVisible = (e) => {
    e.preventDefault();
    setVisible({ ...initialState, about: !visible.about });
  };

  const onOverlayClick = () => {
    setVisible({ ...initialState });
  };

  useEffect(() => {
    setVisible({ ...initialState });
  }, [pathname]);

  return (
    <nav id="nav" className={styles.nav} aria-label="Main">
      <LocalizedLink to={paths.home} className={styles.navItem}>
        {t({ id: "nav.home" })}
      </LocalizedLink>
      <LocalizedLink to={paths.news} className={classnames(styles.navItem, newsActive() && styles.active)}>
        {t({ id: "nav.aktualnosci" })}
      </LocalizedLink>
      <>
        <button
          onClick={setServicesVisible}
          className={classnames(styles.navItem, servicesActive() && styles.active)}
        >
          {t({ id: "nav.oferta" })}
          <div className={classnames(styles.arrow, visible.services && styles.active)}></div>
        </button>
        <Dropdown visible={visible.services} onOverlayClick={onOverlayClick}>
          <ServicesDropdown />
        </Dropdown>
      </>
      <>
        <button
          onClick={setAboutVisible}
          className={classnames(styles.navItem, aboutActive() && styles.active)}
        >
          {t({ id: "nav.o-nas" })}
          <div className={classnames(styles.arrow, visible.about && styles.active)}></div>
        </button>
      </>
      <Dropdown visible={visible.about} onOverlayClick={onOverlayClick}>
        <AboutDropdown />
      </Dropdown>
      <LocalizedLink to={paths.pricing} className={styles.navItem}>
        {t({ id: "nav.cennik" })}
      </LocalizedLink>
      <LocalizedLink to={paths.patients} className={styles.navItem}>
        <span>{t({ id: "nav.strefa-pacjenta" })}</span>
      </LocalizedLink>
      <LocalizedLink to={paths.contact} className={styles.navItem}>
        {t({ id: "nav.kontakt" })}
      </LocalizedLink>
      <div className={styles.navItem}>
        <div className="w-auto px-2 rounded-full bg-blue-900">
          <a href="https://cmaesthetic.pl" target="_blank" className="text-sm leading-tight text-white font-lato tracking-normal font-bold hover:no-underline whitespace-nowrap">Centrum Medyczne<OutIcon className="ml-1 inline-block" /></a>
        </div>
      </div>
    </nav>
  );
};

export default NavDesktop;
