import React, { useState } from "react";

import Slider from "./Slider";
import * as styles from "./Slider.module.scss";

const SliderWrapper = ({ children }) => {
  const [current, setCurrent] = useState(0);
  const pages = Array.from(children).length;

  const onClickRight = () => setCurrent((c) => c + 1);
  const onClickLeft = () => setCurrent((c) => c - 1);

  const shouldRenderRight = current !== pages - 1;
  const shouldRenderLeft = current !== 0;

  return (
    <div style={{ display: "grid", width: "100%", position: "relative" }}>
      {shouldRenderLeft && (
        <button
          className={`${styles.button} ${styles.button__left}`}
          onClick={onClickLeft}
          aria-label="previous picture"
        >
          <span className={styles.chevron} />
        </button>
      )}
      {shouldRenderRight && (
        <button
          className={`${styles.button} ${styles.button__right}`}
          onClick={onClickRight}
          aria-label="next picture"
        >
          <span className={styles.chevron} />
        </button>
      )}
      <Slider current={current}>{children}</Slider>
    </div>
  );
};

export default SliderWrapper;
