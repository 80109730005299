import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";

import Slider from "components/Slider";
import Button from "common/Button";
import MainHero from "components/MainHero";
import SEO from "components/Seo";
import Specialties from "components/Specialties/Specialties";
import MainPoints from "components/MainPoints";
import IndexJsonLd from "components/IndexJsonLd";

const IndexPage = () => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      <IndexJsonLd />
      <SEO title={t({ id: "index.seo.title" })} />
      <main>
        <MainHero />
        <article>
          <MainPoints />
          <Specialties />
          <section className="container-md--pad ix-gallery">
            <Slider>
              <div className="slide">
                <figure className="picture">
                  <StaticImage
                    src={"../images/gabinet-2.jpg"}
                    className="image"
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                    fadeIn={true}
                    layout="constrained"
                    alt={t({ id: "o-nas.gabinety.pictureCaption.3" })}
                    width={1440}
                  />
                </figure>
              </div>
              <div className="slide">
                <figure className="picture">
                  <StaticImage
                    src={"../images/gabinet-3.jpg"}
                    className="image"
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                    fadeIn={true}
                    layout="constrained"
                    alt={t({ id: "o-nas.gabinety.pictureCaption.3" })}
                    width={1440}
                  />
                </figure>
              </div>
              <div className="slide">
                <figure className="picture">
                  <StaticImage
                    src={"../images/gabinet-5.jpg"}
                    className="image"
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                    fadeIn={true}
                    layout="constrained"
                    alt={t({ id: "o-nas.gabinety.pictureCaption.3" })}
                    width={1440}
                  />
                </figure>
              </div>
              <div className="slide">
                <figure className="picture">
                  <StaticImage
                    src={"../images/gabinet-4.jpg"}
                    className="image"
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                    fadeIn={true}
                    layout="constrained"
                    alt={t({ id: "o-nas.gabinety.pictureCaption.4" })}
                    width={1440}
                  />
                </figure>
              </div>
              <div className="slide">
                <figure className="picture">
                  <StaticImage
                    src={"../images/gabinet-1.jpg"}
                    className="image"
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                    fadeIn={true}
                    layout="constrained"
                    alt={t({ id: "o-nas.gabinety.pictureCaption.1" })}
                    width={1440}
                  />
                </figure>
              </div>
            </Slider>
          </section>
          <section className="container-md--pad ix-contact">
            <div className="grid-2">
              <h2 className="ix-contact__title">{t({ id: "index.kontakt.title" })}</h2>
              <div>
                <p className="ix-contact__text">{t({ id: "index.kontakt.text" })}</p>
                <Button to="/kontakt/">{t({ id: "index.fold.a.kontakt" })}</Button>
              </div>
            </div>
          </section>
        </article>
      </main>
    </>
  );
};

export default IndexPage;
