import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion";
import SEO from "components/Seo";
import { range } from "utils/range";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "ortognatyka.deformacje-szczekowo-twarzowe" },
  { key: "ortognatyka.typy-zabiegow" },
  { key: "ortognatyka.moja-historia" },
  { key: "ortodoncja" },
];

const OrtognatykaPage = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SEO
        title={formatMessage({ id: "oferta.ortognatyka.seo.title" })}
        description={formatMessage({
          id: "oferta.ortognatyka.seo.description",
        })}
      />
      <main>
        <article className={styles.article}>
          <section>
            <div className={styles.text}>
              <h1>{formatMessage({ id: "oferta.ortognatyka.h1" })}</h1>
              <FormattedMessage id="oferta.ortognatyka.p.1">
                {(msg) => <p className={styles.noMargin}>{msg}</p>}
              </FormattedMessage>
              <ul>
                <FormattedMessage id="oferta.ortognatyka.p.1.1" tagName="li" />
                <FormattedMessage id="oferta.ortognatyka.p.1.2" tagName="li" />
                <FormattedMessage id="oferta.ortognatyka.p.1.3" tagName="li" />
              </ul>
              <FormattedMessage id="oferta.ortognatyka.p.2" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.p.3" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.p.4" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.p.5" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.p.6" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.p.7" tagName="p" />
            </div>
          </section>
          <section>
            <div className={styles.text}>
              <FormattedMessage id="oferta.ortognatyka.przebieg.h2" tagName="h3" />
              <ol className={styles.ordered__special}>
                {range(1, 7).map((i) => (
                  <FormattedMessage id={`oferta.ortognatyka.przebieg.li.${i}`}>
                    {(msg) => (
                      <li>
                        {msg}
                        <FormattedMessage
                          id={`oferta.ortognatyka.przebieg.li.${i}.p`}
                          tagName="p"
                        />
                      </li>
                    )}
                  </FormattedMessage>
                ))}
              </ol>
            </div>
            <div className={styles.text}>
              <FormattedMessage id="oferta.ortognatyka.pierwsza-konsultacja.h2" tagName="h3" />
              <FormattedMessage id="oferta.ortognatyka.pierwsza-konsultacja.p.1">
                {(msg) => <p>{msg}</p>}
              </FormattedMessage>
              <ul>
                <FormattedMessage
                  id="oferta.ortognatyka.pierwsza-konsultacja.p.1.li.1"
                  tagName="li"
                />
                <FormattedMessage
                  id="oferta.ortognatyka.pierwsza-konsultacja.p.1.li.2"
                  tagName="li"
                />
                <FormattedMessage
                  id="oferta.ortognatyka.pierwsza-konsultacja.p.1.li.3"
                  tagName="li"
                />
              </ul>
              <FormattedMessage id="oferta.ortognatyka.pierwsza-konsultacja.p.2">
                {(msg) => <p className={styles.noMargin}>{msg}</p>}
              </FormattedMessage>
              <ul>
                <FormattedMessage
                  id="oferta.ortognatyka.pierwsza-konsultacja.p.2.li.1"
                  tagName="li"
                />
                <FormattedMessage
                  id="oferta.ortognatyka.pierwsza-konsultacja.p.2.li.2"
                  tagName="li"
                />
                <FormattedMessage
                  id="oferta.ortognatyka.pierwsza-konsultacja.p.2.li.3"
                  tagName="li"
                />
                <FormattedMessage
                  id="oferta.ortognatyka.pierwsza-konsultacja.p.2.li.4"
                  tagName="li"
                />
                <FormattedMessage
                  id="oferta.ortognatyka.pierwsza-konsultacja.p.2.li.5"
                  tagName="li"
                />
              </ul>
            </div>
            <div className={styles.text}>
              <FormattedMessage id="oferta.ortognatyka.zabieg.h2" tagName="h3" />
              <FormattedMessage id="oferta.ortognatyka.zabieg.p.1" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.zabieg.p.2" tagName="p" />
            </div>

            <div className={styles.text}>
              <FormattedMessage id="oferta.ortognatyka.retencja.h2" tagName="h3" />
              <FormattedMessage id="oferta.ortognatyka.retencja.p.1" tagName="p" />
            </div>
          </section>
        </article>
        <article className={styles.article}>
          <section className={styles.text}>
            <h2>FAQ</h2>
            <Accordion>
              {range(1, 10).map((i) => (
                <AccordionItem>
                  <AccordionItemHeading ariaLevel={2}>
                    <FormattedMessage id={`faq.ortognatyka.pytanie.${i}`} tagName="h3" ś />
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <FormattedMessage id={`faq.ortognatyka.odpowiedz.${i}`} tagName="p" />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default OrtognatykaPage;
