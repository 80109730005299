import "styles/moja-historia.scss";
import "styles/ofertaDetail.scss";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Layout from "components/Layout";
import PictureCaption from "components/PictureCaption";
import SEO from "components/Seo";

const services = [
  { key: "ortognatyka.deformacje-szczekowo-twarzowe" },
  { key: "ortognatyka.typy-zabiegow" },
  { key: "ortognatyka" },
  { key: "ortodoncja" },
];

const MojaHistoriaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      przedImage1: file(relativePath: { eq: "przed-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      przedImage2: file(relativePath: { eq: "przed-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wTrakcieImage: file(relativePath: { eq: "w-trakcie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poImage1: file(relativePath: { eq: "po-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      poImage2: file(relativePath: { eq: "po-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 50) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const { formatMessage } = useIntl();
  return (
    <>
      <SEO
        title={formatMessage({
          id: "oferta.ortognatyka.moja-historia.seo.title",
        })}
        description={formatMessage({ id: "oferta.ortognatyka.moja-historia.seo.description" })}
      />
      <main>
        <article className="article">
          <section>
            <div className="text">
              <h1>{formatMessage({ id: "oferta.ortognatyka.moja-historia.h1" })}</h1>
              <p className="rich-text">
                {formatMessage({ id: "oferta.ortognatyka.moja-historia.p.0" })}
              </p>
            </div>
            <div className="double-picture-row">
              <figure className="picture">
                <FormattedMessage id="oferta.ortognatyka.moja-historia.picture.1">
                  {(msg) => (
                    <>
                      <Img fluid={data.przedImage1.childImageSharp.fluid} alt={msg} fadeIn={true} />
                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
              <figure className="picture">
                <FormattedMessage id="oferta.ortognatyka.moja-historia.picture.2">
                  {(msg) => (
                    <>
                      <Img fluid={data.przedImage2.childImageSharp.fluid} alt={msg} fadeIn={true} />
                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
            </div>
            <div className="text">
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.1" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.2" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.3" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.richQuote.1">
                {(msg) => <q className="quote">{msg}</q>}
              </FormattedMessage>
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.4" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.5" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.6" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.7" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.richQuote.2">
                {(msg) => <q className="quote">{msg}</q>}
              </FormattedMessage>
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.8" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.9" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.10" tagName="p" />
            </div>
            <div className="single-picture-row">
              <figure className="picture">
                <FormattedMessage id="oferta.ortognatyka.moja-historia.picture.3">
                  {(msg) => (
                    <>
                      <Img
                        fluid={data.wTrakcieImage.childImageSharp.fluid}
                        alt={msg}
                        fadeIn={true}
                      />
                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
            </div>
            <div className="text">
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.11" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.12" tagName="p" />
              <FormattedMessage id="oferta.ortognatyka.moja-historia.p.13" tagName="p" />
            </div>
            <div className="single-picture-row">
              <figure className="picture">
                <FormattedMessage id="oferta.ortognatyka.moja-historia.picture.4">
                  {(msg) => (
                    <>
                      <Img fluid={data.poImage1.childImageSharp.fluid} alt={msg} fadeIn={true} />
                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
            </div>
            <div className="single-picture-row">
              <figure className="picture">
                <FormattedMessage id="oferta.ortognatyka.moja-historia.picture.5">
                  {(msg) => (
                    <>
                      <Img fluid={data.poImage2.childImageSharp.fluid} alt={msg} fadeIn={true} />
                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default MojaHistoriaPage;
