import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Layout from "components/Layout";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "ortognatyka.moja-historia" },
  { key: "ortognatyka.typy-zabiegow" },
  { key: "ortognatyka" },
  { key: "ortodoncja" },
];

const DeformacjeSzczekowoTwarzowePage = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SEO
        title={formatMessage({
          id: "oferta.ortognatyka.deformacje-szczekowo-twarzowe.h1",
        })}
      />
      <main>
        <article className={styles.article}>
          <section>
            <h1>
              {formatMessage({
                id: "oferta.ortognatyka.deformacje-szczekowo-twarzowe.h1",
              })}
            </h1>
            <div className={styles.text}>
              <FormattedMessage
                id="oferta.ortognatyka.deformacje-szczekowo-twarzowe.p.1"
                tagName="p"
              />
              <FormattedMessage
                id="oferta.ortognatyka.deformacje-szczekowo-twarzowe.p.2"
                tagName="p"
              />
              <FormattedMessage
                id="oferta.ortognatyka.deformacje-szczekowo-twarzowe.p.3"
                tagName="p"
              />
            </div>
            <div className={styles.text}>
              <FormattedMessage
                id="oferta.ortognatyka.deformacje-szczekowo-twarzowe.h.2"
                tagName="h3"
              />
              <FormattedMessage
                id="oferta.ortognatyka.deformacje-szczekowo-twarzowe.p.4"
                tagName="p"
              />
              <FormattedMessage
                id="oferta.ortognatyka.deformacje-szczekowo-twarzowe.p.5"
                tagName="p"
              />
              <FormattedMessage
                id="oferta.ortognatyka.deformacje-szczekowo-twarzowe.p.6"
                tagName="p"
              />
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default DeformacjeSzczekowoTwarzowePage;
