import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";

import Button from "common/Button";
import Figure from "common/Figure";

import * as styles from "./Surgery.module.scss";
import * as specialtiesCss from "../Specialties.module.scss";

const Surgery = () => {
  const { formatMessage: t } = useIntl();

  return (
    <div className={styles.layout}>
      <div className={styles.text}>
        <h2 className={specialtiesCss.title}>Leczymy skomplikowane wady zgryzu</h2>
        <p>
          Przeprowadzamy całkowite leczenie wad zgryzu i regeneracje uśmiechu. W Twoją przemianę
          zaangażowani są lekarze ortodonci, chirurdzy oraz protetycy.
        </p>
        <Button to="/ortognatyka/moja-historia/">Poznaj historię Agnieszki</Button>
      </div>
      <Figure>
        <StaticImage
          src={"../../../images/surgery.jpg"}
          className={specialtiesCss.image}
          objectFit="cover"
          objectPosition="center center"
          fadeIn={true}
          layout="constrained"
          alt={t({ id: "index.moja-historia.pictureCaption" })}
          width={1440}
        />
      </Figure>
    </div>
  );
};

export default Surgery;
