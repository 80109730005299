module.exports = {
  'pl-PL': {
    path: "pl",
    locale: "pl-PL",
    text: "Polski",
    default: true,
  },
  // en: {
  //   path: "en",
  //   text: "English",
  // },
  // de: {
  //   path: 'de',
  //   text: 'Deutsch'
  // }
};
