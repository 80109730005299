import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";

import { getLanguageFromPath } from "utils/getLanguageFromPath";
import Layout from "components/Layout";
import locales from "i18n/locales";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import "./src/styles/app.scss";
import "./src/styles/global.css";
import { PresentationContext } from "./src/context/PresentationContext";
import { Script } from "gatsby";

const IntlLoader = (props) => {
  const [state, setState] = useState(() => ({
    messages: window.__messages,
    language: window.__language
  }));

  const loadMessages = async (language) => {
    const messages = await import(`./src/i18n/${language}.json`).default;
    setState({ messages, language });
  };

  useEffect(() => {
    if (state.language !== props.language) {
      loadMessages(props.language);
    }
  }, [props.language, state.language]);

  const { language, ...rest } = props;

  return (
    <IntlProvider
      locale={state.language}
      messages={state.messages}
      key={state.language}
      {...rest}
    />
  );
};

const GOOGLE_COOKIE_MAP = {
  storage: "ad_storage",
  analytics: ["analytics_storage", "ad_storage"],
  ads: ["ad_personalization", "ad_user_data"]
};

const getGrantedConsents = function(categories) {
  let result = [];

  for (let i = 0; i < categories.length; i++) {
    if (GOOGLE_COOKIE_MAP[categories[i]]) {
      if (Array.isArray(GOOGLE_COOKIE_MAP[categories[i]])) {
        result = result.concat(GOOGLE_COOKIE_MAP[categories[i]]);
      } else {
        result.push(GOOGLE_COOKIE_MAP[categories[i]]);
      }
    }
  }

  return result;
};

const getDeniedConsents = function(categories) {
  let result = Object.keys(GOOGLE_COOKIE_MAP)
    .filter((key) => !categories.includes(key))
    .flatMap((key) => GOOGLE_COOKIE_MAP[key]);

  result = [...new Set(result)];
  return result;
}

export const onInitialClientRender = () => {
  CookieConsent.run(
    {
      mode: "opt-in",
      guiOptions: {
        consentModal: {
          layout: "box",
          position: "bottom right",
          equalWeightButtons: true,
          flipButtons: false
        },
        preferencesModal: {
          layout: "box",
          position: "right",
          equalWeightButtons: true,
          flipButtons: false
        }
      },
      categories: {
        necessary: {
          readOnly: true
        },
        analytics: {
          autoClear: {
            cookies: [
              {
                name: /^(_ga)/
              },
              {
                name: "_gid"
              }
            ]
          }
        },
        ads: {
          autoClear: {
            cookies: [
              {
                name: "_gcl_au"
              }
            ]
          }
        }
      },
      language: {
        default: "pl",
        autoDetect: "browser",
        translations: {
          pl: {
            consentModal: {
              title: "Aesthetic Dent korzysta z ciastek",
              description: "Klikając \"Zaakceptuj wszystkie\", pozwalasz nam w pełni dostosować serwis Aesthetic Dent do Twoich preferencji. Korzystamy z plików cookies głównie po to, aby zwiększyć użyteczność serwisu oraz zbierać dane statystyczne. Zawsze możesz zmienić zdanie i zmienić ustawienia cookies.",
              acceptAllBtn: "Zaakceptuj wszystkie",
              acceptNecessaryBtn: "Zaakceptuj tylko wymagane",
              showPreferencesBtn: "Zmień preferencje",
              footer: "<a href=\"/strefa-pacjenta/polityka-prywatnosci\">Polityka Prywatności</a>",
              equalWeightButtons: true
            },
            preferencesModal: {
              title: "Ustawienia cookies",
              acceptAllBtn: "Zaakceptuj wszystkie",
              acceptNecessaryBtn: "Zaakceptuj tylko wymagane",
              savePreferencesBtn: "Zaakceptuj wybrane",
              closeIconLabel: "Zamknij",
              serviceCounterLabel: "Usługa|Usługi",
              equalWeightButtons: true,
              sections: [
                {
                  title: "",
                  description: "Tutaj możesz sprawdzić, w jaki sposób korzystamy z plików cookies. Zaakceptuj wszystkie, aby korzystać z serwisu Aesthetic Dent w najbardziej optymalny sposób. Możesz jednak wyłączyć niektóre cookies."
                },
                {
                  title: "Cookies niezbędne",
                  description: "Są one niezbędne do zapisania preferencji dotyczących cookies. Bez nich nie będziemy mogli zapamiętać Twoich ustawień.",
                  linkedCategory: "necessary"
                },
                {
                  title: "Cookies analityczne",
                  description: "Wykorzystujemy je do obserwowania, jak nasi użytkownicy korzystają z naszych usług oraz do gromadzenia statystyk. Możesz wyłączyć te pliki cookies.",
                  linkedCategory: "analytics",
                  cookieTable: {
                    caption: "Lista cookies analitycznych",
                    headers: {
                      name: "Nazwa",
                      description: "Opis",
                      duration: "Czas trwania"
                    },
                    body: [
                      {
                        name: "^_ga",
                        duration: "2 lata",
                        description: "To ciasteczko służy do rozróżniania unikalnych użytkowników, przypisując każdemu przeglądarce użytkownika losowo wygenerowany unikalny identyfikator. Służy to do śledzenia sesji użytkowników oraz ich interakcji w czasie."
                      },
                      {
                        name: "_gid",
                        duration: "1 dzień",
                        description: "To ciasteczko służy do rozróżniania użytkowników w trakcie pojedynczej sesji przeglądania. Jest to mechanizm śledzenia krótkoterminowego w porównaniu do _ga i zazwyczaj jest używane do zrozumienia zachowania użytkowników w ramach pojedynczej sesji."
                      }
                    ]
                  }
                },
                {
                  title: "Cookies marketingowe",
                  description: "Pozwalają nam na wyświetlanie Ci reklam naszych usług. Korzystamy z tych plików we współpracy z naszymi partnerami marketingowymi, np. takimi jak Facebook czy Google. Możesz wyłączyć te pliki cookies.",
                  linkedCategory: "ads",
                  cookieTable: {
                    caption: "Lista cookies marketingowych",
                    headers: {
                      name: "Nazwa",
                      description: "Opis",
                      duration: "Czas trwania"
                    },
                    body: [
                      {
                        name: "_gcl_au",
                        duration: "90 dni",
                        description: "To ciasteczko jest używane przez Google Ads do mierzenia efektów konwersji naszych reklam w wyszukiwacje Google."
                      }
                    ]
                  }
                },
                {
                  title: "Więcej informacji",
                  description: "Jeżeli chcesz uzyskać więcej informacji odnośnie cookies i przetwarzania danych osobowych, zapoznaj się z <a href=\"https://aestheticdent.pl/strefa-pacjenta/polityka-prywatnosci\">naszą polityką cookies i polityką przetwarzania danych osobowych</a>."
                }
              ]
            }
          }
        }
      },
      disablePageInteraction: false,
      onConsent: function({ cookie }) {
        const grantedConsents = getGrantedConsents(cookie.categories);
        const consents = {};

        for (let i = 0; i < grantedConsents.length; i++) {
          consents[grantedConsents[i]] = "granted";
        }
        const deniedConsents = getDeniedConsents(cookie.categories);

        for (let i = 0; i < grantedConsents.length; i++) {
          consents[grantedConsents[i]] = "granted";
        }
        for (let i = 0; i < deniedConsents.length; i++) {
          consents[deniedConsents[i]] = "denied";
        }

        gtag("consent", "update", consents);
      },

      onChange: function({ cookie }) {
        const grantedConsents = getGrantedConsents(cookie.categories);
        const consents = {};

        const deniedConsents = getDeniedConsents(cookie.categories);

        for (let i = 0; i < grantedConsents.length; i++) {
          consents[grantedConsents[i]] = "granted";
        }
        for (let i = 0; i < deniedConsents.length; i++) {
          consents[deniedConsents[i]] = "denied";
        }

        gtag("consent", "update", consents);
      }
    }
  );
};

// export const wrapRootElement = ({ element }) => {
//   return (
//     <>
//       <Script>
//         {
//           `
//             window.dataLayer = window.dataLayer || [];
//             function gtag() { dataLayer.push(arguments); console.log('pushing', arguments); }
//             gtag('js', new Date());
//             gtag('consent', 'default', {
//               "ad_storage": "denied",
//               "analytics_storage": "denied",
//               "ad_personalization": "denied",
//               "ad_user_data": "denied",
//               'wait_for_update': 500
//             });
//             `
//         }
//       </Script>
//       {element}
//     </>
//   );
// };
export const wrapPageElement = ({ element, props }) => {
  const { location } = props;

  const { pathname } = location;

  const language = getLanguageFromPath(pathname, locales);

  return (
    <IntlLoader language={language} {...props}>
      <Layout>
        <PresentationContext.Provider value={{ location }}>
          {element}
        </PresentationContext.Provider>
      </Layout>
    </IntlLoader>
  );
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location?.state?.shouldPreventScroll) {
    return false;
  }

  return true;
};