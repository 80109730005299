import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import LocalizedLink from "common/Link";

import { wrapper, list, title } from "./Services.module.scss";

const Services = ({ services }) => {
  const { formatMessage: t } = useIntl();

  return (
    <>
      <div className={title}>
        <h3>{t({ id: "oferta.powiazane" })}</h3>
      </div>
      <div className={wrapper}>
        <ul className={list}>
          {services.map((service) => (
            <li key={service.key}>
              <LocalizedLink to={`/${service.key.replace(".", "/")}/`}>
                {t({ id: `oferta.${service.key}` })}
              </LocalizedLink>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

Services.propTypes = {
  services: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Services;
