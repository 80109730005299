import React from 'react';
import LocalizedLink from './LocalizedLink';
import { GatsbyImage } from 'gatsby-plugin-image';

const Component = ({ title, publicationDate, excerpt, image, pathname }) => {

    return (
        <div className="w-full flex flex-col md:flex-row gap-8 lg:gap-12 mb-12 md:px-m">
            <GatsbyImage image={image} alt={title} className="max-w-sm object-cover h-full w-full md:w-5/12" />
            <div className="px-m md:px-0 md:w-7/12">
                 <LocalizedLink to={pathname}>
                    <h2 className="h text-xl mb-2">{title}</h2>
                 </LocalizedLink>
                <small className="mb-4 font-lato text-sm font-semibold text-gray-600">{publicationDate}</small>
                <p>{excerpt}</p>
                <LocalizedLink to={pathname} className="standalone-link">czytaj więcej</LocalizedLink>
            </div>
        </div>
    );
}

export default Component;
