import React from "react";
import { useIntl } from "react-intl";
import { range } from "utils/range";

import JsonLd from "common/JsonLd";

const FaqJsonLd = () => {
  const { formatMessage: t } = useIntl();

  const generateQA = (type, [start, end]) => {
    return range(start, end).map((i) => ({
      "@type": "Question",
      name: t({ id: `faq.${type}.pytanie.${i}` }),
      acceptedAnswer: {
        "@type": "Answer",
        text: t({ id: `faq.${type}.odpowiedz.${i}` }),
      },
    }));
  };

  const clinic = generateQA("klinika", [1, 6]);

  return (
    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [
          ...clinic,
        ],
      }}
    </JsonLd>
  );
};

export default FaqJsonLd;
