import Breadcrumbs from "components/Breadcrumbs";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Layout from "components/Layout";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "profilaktyka" },
  { key: "stomatologia-maloinwazyjna" },
  { key: "stomatologia-estetyczna" },
];

const StomatologiaZachowawczaPage = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({
          id: "oferta.stomatologia-zachowawcza.seo.title",
        })}
        description={formatMessage({
          id: "oferta.stomatologia-zachowawcza.seo.description",
        })}
      />
      <main>
        <article className={styles.article}>
          <section>
            <div className={styles.text}>
              <h1>{formatMessage({ id: "oferta.stomatologia-zachowawcza.h1" })}</h1>
              <FormattedMessage id="oferta.stomatologia-zachowawcza.p.1" tagName="p" />
              <FormattedMessage id="oferta.stomatologia-zachowawcza.p.2" tagName="p" />
              <ul>
                <FormattedMessage id="oferta.stomatologia-zachowawcza.ul" tagName="p" />
                <FormattedMessage id="oferta.stomatologia-zachowawcza.li.1" tagName="li" />
                <FormattedMessage id="oferta.stomatologia-zachowawcza.li.2" tagName="li" />
                <FormattedMessage id="oferta.stomatologia-zachowawcza.li.3" tagName="li" />
              </ul>
            </div>
            <div className={styles.text}>
              <FormattedMessage id="oferta.stomatologia-zachowawcza.wypelnienia.h2" tagName="h3" />
              <FormattedMessage id="oferta.stomatologia-zachowawcza.wypelnienia.p.1" tagName="p" />
              <FormattedMessage id="oferta.stomatologia-zachowawcza.wypelnienia.p.2" tagName="p" />
              <FormattedMessage id="oferta.stomatologia-zachowawcza.wypelnienia.p.3" tagName="p" />
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default StomatologiaZachowawczaPage;
