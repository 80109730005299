import React from "react";
import { useIntl } from "react-intl";

import LocalizedLink from "../LocalizedLink";

import * as styles from "./Footer.module.scss";

const navItems = [
  { name: "protetyka" },
  { name: "ortodoncja" },
  { name: "ortognatyka" },
  { name: "implanty" },
  { name: "chirurgia-stomatologiczna" },
  { name: "stomatologia-estetyczna" },
  { name: "stomatologia-zachowawcza" },
  { name: "endodoncja" },
  { name: "radiologia" },
  { name: "stomatologia-maloinwazyjna" },
  { name: "profilaktyka" },
];

const OfertaNav = () => {
  const { formatMessage } = useIntl();

  return (
    <ul className={`${styles.navList} ${styles.offer}`}>
      {navItems.map(({ name }) => {
        return (
          <li key={name}>
            <LocalizedLink to={`/${name}`} name={name}>
              {formatMessage({ id: `oferta.${name}` })}
            </LocalizedLink>
          </li>
        );
      })}
    </ul>
  );
};

export default OfertaNav;
