import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import { useIntl } from "react-intl"

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemPanel } from "components/Accordion";
import PageIntro from "common/PageIntro";
import SEO from "components/Seo";

import * as styles from "styles/cennik.module.scss";
import * as pageStyles from "styles/page-styles.module.scss";

const CennikPage = ({ data }) => {
  const { seo, group = [] } = data?.allStrapiPricing?.edges?.[0].node;

  const { formatMessage: t } = useIntl();

  return (
    <>
      <main>
        <PageIntro title={seo.metaTitle} centered />
        <article>
          <section className={pageStyles.baseSection}>
            <div className={classNames(pageStyles.appContainer, styles.cennik)}>
              <Accordion>
                {group.map((group) => (
                  <AccordionItem key={group.id}>
                    <AccordionItemHeading ariaLevel={2}>
                      <h3>{group.name}</h3>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="cennik">
                      <table>
                        <tbody>
                          {group.entry.map(({ title, details, price, priceInfo, priceFrom, id }) => (
                            <tr key={id}>
                              <td>
                                <h4>{title}</h4>
                                <p>{details}</p>
                              </td>
                              <td>{priceFrom ? t({ id: 'global.from' }) : null} {price} {priceInfo}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
              <FormattedMessage id="cennik.bottom">{(msg) => <p className={styles.bottom}>{msg}</p>}</FormattedMessage>
            </div>
          </section>
        </article>
      </main>
    </>
  );
};

export const Head = ({ data }) => {
  const { seo } = data?.allStrapiPricing?.edges?.[0].node;

  return <SEO title={seo.metaTitle} description={seo.metaDescription} />;
}

export const pageQuery = graphql`
  query ($locale: String!) {
    allStrapiPricing(filter: {locale: {eq: $locale }}) {
      edges {
        node {
          id
          seo {
            metaTitle
            metaDescription
          }
          group {
            id
            name
            entry {
              title
              details
              price
              priceInfo
              priceFrom
              id
            }
          }
        }
      }
    }
  }
`;

export default CennikPage;
