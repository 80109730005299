import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "react-intl";

import paths from "config/paths";
import Button from "common/Button";

import * as styles from "./MainHero.module.scss";
import { SMediaDown, SMediaUp } from "common/Media";

const MainHero = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.mainHero}>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <h1 className={styles.title}>Piękny uśmiech zaczyna się tutaj</h1>
          <h2 className={styles.text}>
            W naszych gabinetach stomatologicznych w Szczecinie i Gorzowie Wielkopolskim oferujemy usługi z zakresu
            protetyki, implantologii, chirurgii i ortodoncji.
          </h2>
          {/* <div className={styles.linkRow}> */}
          {/* <LocalizedLink to="/nasze-specjalnosci" className="link"> */}
          {/* {formatMessage({ id: 'index.fold.a.nasze-specjalnosci' })} */}
          {/* </LocalizedLink> */}
          <Button to={paths.contact} data-track-click data-track-category="leadgen" data-track-component="book_hero">
            {formatMessage({ id: "index.fold.a.kontakt" })}
          </Button>
          {/* </div> */}
        </div>
        <div className={styles.right}>
          <SMediaUp>
            <StaticImage
              src={"../../images/hero_4.jpg"}
              className={styles.rightImage}
              objectFit="cover"
              objectPosition="center center"
              layout="constrained"
              alt={formatMessage({ id: "index.alt.main" })}
              loading="eager"
              width={1440}
            />
          </SMediaUp>
          <SMediaDown>
            <StaticImage
              src={"../../images/hero.jpg"}
              className={styles.rightImage}
              objectFit="cover"
              objectPosition="center center"
              fadeIn={true}
              layout="constrained"
              loading="eager"
              alt={formatMessage({ id: "index.alt.main" })}
              width={1440}
            />
          </SMediaDown>
        </div>
      </div>
    </div>
  );
};

export default MainHero;
