import React from "react";
import { useIntl } from "react-intl";

import { range } from "utils/range";

import JsonLd from "common/JsonLd";

const AllOn4JsonLd = () => {
  const { formatMessage: t } = useIntl();

  const generateQA = (type, [start, end]) => {
    return range(start, end).map((i) => ({
      "@type": "Question",
      name: t({ id: `${type}.faq.pytanie.${i}` }),
      acceptedAnswer: {
        "@type": "Answer",
        text: t({ id: `${type}.faq.odpowiedz.${i}` }),
      },
    }));
  };

  const allOn4 = generateQA("all-on-4", [1, 9]);

  return (
    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: [...allOn4],
      }}
    </JsonLd>
  );
};

export default AllOn4JsonLd;
