import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { GatsbyImage, getImage } from "gatsby-plugin-image";


import Figure from "common/Figure";
import Services from "common/Services/Services";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from "components/Accordion";
import SEO from "components/Seo";
import PictureCaption from "components/PictureCaption";
import { range } from "utils/range";

import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "protetyka.korony" },
  { key: "licowki-i-kompozyty" },
  { key: "stomatologia-maloinwazyjna" },
  { key: "protetyka.stomatologia-3d" },
  { key: "stomatologia-estetyczna" },
  { key: "implanty" },
];

const ProtetykaPage = () => {
  const { formatMessage } = useIntl();
  const { prostheticsTeamPicture } = useStaticQuery(query);

  return (
    <>
      <SEO title={formatMessage({ id: "oferta.protetyka.seo.title" })} />
      <main>
        <article className={styles.article}>
          <section>
            <div className={styles.text}>
              <h1>{formatMessage({ id: "oferta.protetyka.h1" })}</h1>
              <FormattedMessage id="oferta.protetyka.p.1" tagName="p" />
              <FormattedMessage id="oferta.protetyka.p.2" tagName="p" />
              <FormattedMessage id="oferta.protetyka.wskazania">
                {(msg) => <p className={styles.noMargin}>{msg}</p>}
              </FormattedMessage>
              <ul>
                <FormattedMessage id="oferta.protetyka.wskazania.li.1" tagName="li" />
                <FormattedMessage id="oferta.protetyka.wskazania.li.2" tagName="li" />
                <FormattedMessage id="oferta.protetyka.wskazania.li.3" tagName="li" />
                <FormattedMessage id="oferta.protetyka.wskazania.li.4" tagName="li" />
              </ul>
            </div>
            <Figure flow>
              <GatsbyImage
                image={getImage(prostheticsTeamPicture)}
                alt="Zespół protetyczny Aesthetic Dent w Szczcinie."
              />
              <PictureCaption caption="Zespół protetyczny Aesthetic Dent w Szczcinie." />
            </Figure>
          </section>
        </article>
        <article className={styles.article}>
          <section>
            <h3>FAQ</h3>
            <Accordion>
              {range(1, 6).map((i) => (
                <AccordionItem>
                  <AccordionItemHeading ariaLevel={2}>
                    <FormattedMessage id={`faq.protetyka.pytanie.${i}`} tagName="h3" />
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <FormattedMessage id={`faq.protetyka.odpowiedz.${i}`} tagName="p" />
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

const query = graphql`
  query {
    prostheticsTeamPicture: file(relativePath: { eq: "prosthetics-team.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 700
        )
      }
    }
  }
`;

export default ProtetykaPage;
