import Breadcrumbs from "components/Breadcrumbs";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Layout from "components/Layout";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "stomatologia-zachowawcza" },
  { key: "endodoncja" },
  { key: "profilaktyka" },
];

const RadiologiaPage = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SEO
        title={formatMessage({ id: "oferta.radiologia.seo.title" })}
        description={formatMessage({ id: "oferta.radiologia.seo.description" })}
      />
      <main>
        <article className={styles.article}>
          <section>
            <div className={styles.text}>
              <h1>{formatMessage({ id: "oferta.radiologia.h1" })}</h1>
              <FormattedMessage id="oferta.radiologia.p.1" tagName="p" />
              <FormattedMessage id="oferta.radiologia.p.2" tagName="p" />
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default RadiologiaPage;
