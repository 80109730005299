import React from "react";

const Out = ({ className }) => (
  <svg width="16px" height="16px" viewBox="0 0 23 24" className={className}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-81.000000, -1944.000000)" stroke="currentColor" strokeWidth="2.4">
        <g transform="translate(82.000000, 1945.000000)">
          <path
            d="M19.5,10.2989473 L19.5,10.2989473 L19.5,20.4 C19.5,20.8970563 19.0970563,21.3 18.6,21.3 L0.9,21.3 C0.402943725,21.3 0,20.8970563 0,20.4 L0,2.7 C0,2.20294373 0.402943725,1.8 0.9,1.8 L10.9786835,1.8 L10.9786835,1.8"
            id="Path"
          ></path>
          <g transform="translate(9.600000, 0.000000)">
            <g>
              <line x1="0.15" y1="11.85" x2="11.1" y2="0.75"></line>
              <line x1="11.7" y1="0.75" x2="5.25" y2="0.75" strokeLinejoin="round"></line>
              <line x1="11.1" y1="0.149015905" x2="11.1" y2="6.6" strokeLinejoin="round"></line>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Out;
