import "styles/dzialalnosc-naukowa.scss";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Layout from "components/Layout";
import LocalizedLink from "components/LocalizedLink";
import PictureCaption from "components/PictureCaption";
import SEO from "components/Seo";

import "styles/page-intro.scss";

const ResearchPage = () => {
  const { badaniaImage, publications } = useStaticQuery(query);

  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({ id: "dzialalnosc-naukowa.seo.title" })}
        description={formatMessage({
          id: "dzialalnosc-naukowa.seo.description",
        })}
      />
      <main>
        <div className="container-md page-intro--picture">
          <div className="page-intro--picture__text">
            <h1 className="page-intro--picture__title">
              {formatMessage({ id: "dzialalnosc-naukowa.badania.title" })}
            </h1>
            <p>{formatMessage({ id: "dzialalnosc-naukowa.badania.p.1" })}</p>
            <LocalizedLink to="/dzialalnosc-naukowa/badania/" className="link">
              {formatMessage({ id: "dzialalnosc-naukowa.badania.a" })}
            </LocalizedLink>
          </div>
          <div className="page-intro--picture__picture">
            <figure className="picture">
              <Img fluid={badaniaImage.childImageSharp.fluid} className="image" />
              <FormattedMessage id="dzialalnosc-naukowa.badania.wyniki.caption.1">
                {(msg) => <PictureCaption caption={msg} />}
              </FormattedMessage>
            </figure>
          </div>
        </div>
        <div className="container-md--pad">
          <section>
            <FormattedMessage id="dzialalnosc-naukowa.inne-artykuly.h2" tagName="h3" />
            <div className="publications">
              {publications.edges.map(({ node }) => (
                <div className="publications__item">
                  <div className="main-cell">
                    {node.link !== "" ? (
                      <a href={node.link} className="publication--title">
                        <h4>{node.title}</h4>
                        <div className="caret-container">
                          <div className="caret"></div>
                        </div>
                      </a>
                    ) : (
                      <h4>{node.title}</h4>
                    )}
                    <div className="publication--authors">{node.authors}</div>
                  </div>
                  <div className="side-cell">
                    <div className="publication--date">{node.date}</div>
                    <div className="publication--mag">{node.mag}</div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

const query = graphql`
  query {
    badaniaImage: file(relativePath: { eq: "badania-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    publications: allPublicationsJson {
      edges {
        node {
          title
          link
          authors
          date
          mag
        }
      }
    }
  }
`;

export default ResearchPage;
