const paths = Object.freeze({
  home: "/",
  offer: "/oferta/",
  patients: "/strefa-pacjenta/",
  pricing: "/cennik/",
  contact: "/kontakt/",
  faq: "/faq/",
  research: "/dzialalnosc-naukowa/",
  team: "/zespol/",
  about: "/o-nas/",
  news: "/aktualnosci/",

  allOn4: "/all-on-4/",
  bruxism: "/bruksizm/",
  veneersAndComposites: "/licowki-i-kompozyty/",

  mskierska: "/zespol/malgorzata-skierska/",
  arozyckafalow: "/zespol/agnieszka-rozycka-falow/",
  pmalo: "/zespol/paulo-malo/",
});

export default paths;
