import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";

import LocalizedLink from "components/LocalizedLink";
import paths from "config/paths";
import { useLocation } from "@reach/router";

import OutIcon from "images/svg/out";

import Overlay from "./Overlay";
import Dropdown from "./Dropdown";
import Hamburger from "./Hamburger";
import { services } from "./static";

import * as styles from "./NavMobile.module.scss";
import { dropdownSection, dropdownSectionTitle, contact } from "./NavMobile.module.scss";

const NavMobile = () => {
  const { pathname } = useLocation();
  const { formatMessage: t } = useIntl();

  const [toggle, setToggle] = useState(false);
  const [dropdownState, setDropdownState] = useState({
    about: false,
    services: false,
  });

  const handleHamburgerClick = () => {
    setToggle((toggle) => !toggle);
  };

  const handleDropdownClick = (key) => {
    setDropdownState((state) => ({
      ...state,
      [key]: !state[key],
    }));
  };


  useEffect(() => {
    setToggle(false);
  }, [pathname]);

  return (
    <nav>
      <Hamburger toggle={toggle} onClick={handleHamburgerClick} />
      {toggle && <Overlay />}
      <div className={classNames(styles.navContainer, toggle && styles.toggled)} id="drawer-menu">
        <ul className={classNames(styles.nav, toggle && styles.toggled)}>
          <li>
            <LocalizedLink to={paths.home} className={styles.navItem}>
              {t({ id: "nav.home" })}
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink to={paths.news} className={styles.navItem}>
              {t({ id: "nav.aktualnosci" })}
            </LocalizedLink>
          </li>
          <li>
            <button
              className={classNames(styles.navItem, dropdownState.services && styles.active)}
              onClick={() => handleDropdownClick("services")}
              aria-expanded={dropdownState.services}
            >
              {t({ id: "nav.oferta" })}
              <div
                className={classNames(styles.chevron, dropdownState.services && styles.toggled)}
              />
            </button>
            <Dropdown visible={dropdownState.services}>
              <span className={dropdownSectionTitle}>Nasze Specjalności</span>
              <div className={styles.divider} />
              <div className={dropdownSection}>
                <LocalizedLink to={paths.allOn4} className={styles.navItem}>
                  All-on-4&trade;
                </LocalizedLink>
                <LocalizedLink to={paths.veneersAndComposites} className={styles.navItem}>
                  Licówki i kompozyty
                </LocalizedLink>
                <LocalizedLink to={paths.bruxism} className={styles.navItem}>
                  Bruksizm
                </LocalizedLink>
              </div>
              <span className={dropdownSectionTitle}>Oferta</span>
              <div className={styles.divider} />
              <div className={dropdownSection}>
                {services.map((service) => {
                  return (
                    <LocalizedLink
                      to={`/${service.key}/`}
                      className={styles.navItem}
                      key={service.key}
                    >
                      {t({ id: `breadcrumb.${service.key}` })}
                    </LocalizedLink>
                  );
                })}
              </div>
            </Dropdown>
          </li>
          <li>
            <button
              className={classNames(styles.navItem, dropdownState.about && styles.active)}
              onClick={() => handleDropdownClick("about")}
            >
              {t({ id: "nav.o-nas" })}
              <div className={classNames(styles.chevron, dropdownState.about && styles.toggled)} />
            </button>
            <Dropdown visible={dropdownState.about}>
              <div className={dropdownSection}>
                <LocalizedLink to={paths.about} className={styles.navItem}>
                  {t({ id: "nav.o-nas" })}
                </LocalizedLink>
                <LocalizedLink to={paths.team} className={styles.navItem}>
                  {t({ id: "nav.zespol" })}
                </LocalizedLink>
                <LocalizedLink to={paths.research} className={styles.navItem}>
                  {t({ id: "nav.dzialalnosc-naukowa" })}
                </LocalizedLink>
              </div>
            </Dropdown>
          </li>
          <div className={styles.divider} />
          <li>
            <LocalizedLink to={paths.pricing} className={styles.navItem}>
              {t({ id: "nav.cennik" })}
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink to={paths.patients} className={styles.navItem}>
              {t({ id: "nav.strefa-pacjenta" })}
            </LocalizedLink>
          </li>
          <li>
            <LocalizedLink to={paths.contact} className={styles.navItem}>
              {t({ id: "nav.kontakt" })}
            </LocalizedLink>
          </li>
          <li>
            <div className="flex">
              <div className="w-auto px-2 rounded-full bg-blue-900">
                <a href="https://cmaesthetic.pl" target="_blank" className="text-sm leading-tight text-white font-lato tracking-normal font-bold hover:no-underline whitespace-nowrap">Centrum Medyczne<OutIcon className="ml-1 inline-block" /></a>
              </div>
            </div>
          </li>
          <div className={styles.divider} />
          <div className={contact}>
            <small>
              Szczecin:{" "}
              <a href={`tel: ${t({ id: "kontakt.w.tel" })}`}>{t({ id: "kontakt.w.tel" })}</a>
            </small>
            <small>
              Gorzów Wlkp.:{" "}
              <a href={`tel: ${t({ id: "kontakt.g.tel" })}`}>{t({ id: "kontakt.g.tel" })}</a>
            </small>
          </div>
        </ul>
      </div>
    </nav>
  );
};

export default NavMobile;
