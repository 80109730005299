// extracted by mini-css-extract-plugin
export var about__text = "NavDesktop-module--about__text--8f67f";
export var active = "NavDesktop-module--active--71df3";
export var arrow = "NavDesktop-module--arrow--9f408";
export var bigText = "NavDesktop-module--big-text--a97c1";
export var dropdown = "NavDesktop-module--dropdown--b17f0";
export var dropdown__about = "NavDesktop-module--dropdown__about--83654";
export var link = "NavDesktop-module--link--8fc12";
export var nav = "NavDesktop-module--nav--c2b81";
export var navItem = "NavDesktop-module--nav-item--ee22b";
export var offer = "NavDesktop-module--offer--73bcb";
export var overlay = "NavDesktop-module--overlay--75bac";
export var richText = "NavDesktop-module--rich-text--e9b8b";
export var services = "NavDesktop-module--services--c01b1";
export var specialties = "NavDesktop-module--specialties--fd484";
export var text = "NavDesktop-module--text--0fcd9";