import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import SEO from "components/Seo";
import { formatSpaces } from "utils/formatSpaces";
import { formatHyphens } from "utils/formatHyphens";
import paths from "config/paths";
import LocalizedLink from "components/LocalizedLink";

const Component = ({ data }) => {
  const { article, featured, displayFeaturedImage, publicationDate, title } = data.allStrapiNewsPost.edges[0].node;

  const { html } = article.data.childrenMarkdownRemark[0];

  // TODO: placeholder image?
  const { alternativeText, caption, localFile } = featured ?? {};

  const image = getImage(localFile);

  return (
    <main className="main max pt-0">
      <article>
        <div className="flex flex-col gap-y-4 gap-x-8 lg:gap-x-10 mb-8">
          <div className="w-full px-0 md:px-m">
            {displayFeaturedImage ? <GatsbyImage image={image} alt={alternativeText} className="w-full max-h-[32rem]" /> : null}
            {caption ? <small className="font-lota text-gray-600 text-sm mt-2 px-m xl:px-0">{caption}</small> : null}
          </div>
          <div className="w-full px-m">
            <h1 className="h max-w-xl mb-4">{formatHyphens(title)}</h1>
            <small className="mb-6 font-lota text-gray-600 text-sm">{publicationDate}</small>
            <div dangerouslySetInnerHTML={{ __html: formatHyphens(formatSpaces(html)) }} className="news-article" />
          </div>
          <LocalizedLink to={paths.news} className="standalone-link px-m xl:px-0">
            Powrót do artykułów
          </LocalizedLink>
        </div>
      </article>
    </main>
  );
};

export const Head = ({ data }) => {
  const { seo } = data.allStrapiNewsPost.edges[0].node;
  const { url } = seo.metaImage?.formats?.medium ?? {};

  // TODO: meta og image?
  return <SEO title={seo.metaTitle} description={seo.metaDescription} imageUrl={url} />;
}

export const pageQuery = graphql`
  query ($id: String, $locale: String) {
    allStrapiNewsPost(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          title
          locale
          publicationDate(formatString: "DD MMMM, yyyy", locale: $locale)
          article {
            data {
              childrenMarkdownRemark {
                html
              }
            }
          }
          seo {
            metaDescription
            metaTitle
            metaImage {
              formats {
                medium {
                  url
                }
              }
            }
          }
          displayFeaturedImage
          featured {
            alternativeText
            caption
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

export default Component;
