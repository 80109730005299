// extracted by mini-css-extract-plugin
export var alt = "ofertaDetail-module--alt--378c9";
export var article = "ofertaDetail-module--article--d831b";
export var article_container = "ofertaDetail-module--article_container--33f05";
export var article_inner = "ofertaDetail-module--article_inner--881fd";
export var article_inner__header = "ofertaDetail-module--article_inner__header--bb43f";
export var bigText = "ofertaDetail-module--big-text--2c689";
export var centered = "ofertaDetail-module--centered--c1761";
export var container = "ofertaDetail-module--container--a1f1c";
export var container__inner = "ofertaDetail-module--container__inner--fa55b";
export var desktopNone = "ofertaDetail-module--desktop-none--783d2";
export var doublePictureRow = "ofertaDetail-module--double-picture-row--f00a6";
export var enumerated = "ofertaDetail-module--enumerated--6b687";
export var fade = "ofertaDetail-module--fade--fdb02";
export var fadeUp = "ofertaDetail-module--fadeUp--0d806";
export var fadeZoom = "ofertaDetail-module--fadeZoom--c7a21";
export var inline = "ofertaDetail-module--inline--d2567";
export var jsNoscroll = "ofertaDetail-module--js-noscroll--b1e8a";
export var jsVisible = "ofertaDetail-module--js-visible--68f2f";
export var mobileNone = "ofertaDetail-module--mobile-none--33823";
export var ordered = "ofertaDetail-module--ordered--cd79d";
export var ordered__special = "ofertaDetail-module--ordered__special--702e8";
export var pictures = "ofertaDetail-module--pictures--8cc1e";
export var richText = "ofertaDetail-module--rich-text--32d23";
export var singlePicture = "ofertaDetail-module--single-picture--6c576";
export var singlePictureRow = "ofertaDetail-module--single-picture-row--6eb7f";
export var text = "ofertaDetail-module--text--dd5d9";
export var text__big = "ofertaDetail-module--text__big--c9b6b";