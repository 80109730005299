import "../styles/o-nas.scss";
import "../styles/layouts.scss";

import Button from "common/Button";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Layout from "../components/Layout";
import PictureCaption from "../components/PictureCaption";
import SEO from "components/Seo";
import Out from "images/svg/out";

const ONasPage = () => {
  const data = useStaticQuery(query);

  const { formatMessage } = useIntl();

  return (
    <>
      <SEO title={formatMessage({ id: "o-nas.seo.title" })} />
      <main>
        {/* <div className="page-intro">
          <h3 className="page-intro__title">
            Bezpieczne leczenie to podstawa.
          </h3>
          <p className="page-intro__sub">
            Chcemy, aby wizyta u stomatologa stała się przyjemnością.
          </p>
        </div>
        <section className="hero">
          <div className="mobile-none">
            <figure className="picture">
              <FormattedMessage id="o-nas.hero-1.pictureCaption">
                {(msg) => (
                  <>
                    <Img
                      fluid={data.heroImage1.childImageSharp.fluid}
                      className="image"
                      imgStyle={{
                        objectFit: 'cover',
                        objectPosition: 'center center',
                      }}
                      fadeIn={true}
                      alt={msg}
                    />
                    <PictureCaption caption={msg} />
                  </>
                )}
              </FormattedMessage>
            </figure>
          </div>
          <div className="desktop-none">
            <figure className="picture">
              <FormattedMessage id="o-nas.hero-2.pictureCaption">
                {(msg) => (
                  <>
                    <Img
                      fluid={data.heroImage2.childImageSharp.fluid}
                      className="image"
                      imgStyle={{
                        objectFit: 'cover',
                        objectPosition: 'center center',
                      }}
                      fadeIn={true}
                      alt={msg}
                    />
                    <PictureCaption caption={msg} />
                  </>
                )}
              </FormattedMessage>
            </figure>
          </div>
        </section> */}
        <div className="container--pad">
          <div className="grid-1-2">
            <h1 className="section-title">Chcemy, aby leczenie było wyjątkowe</h1>
            <div>
              <p>
                W Aesthetic Dent tworzymy wyjątkową atmosferę leczenia. Dbamy o Twój zdrowy uśmiech
                — z wykorzystaniem nowoczesnych technologii, z profesjonalnym podejściem.
              </p>
              <p>
                Łączymy rzetelne leczenie z poszerzaniem świadomości pacjentów w zakresie
                zapobiegania chorobom uzębienia. Chcemy, aby pozytywne rezultaty leczenia były
                widoczne jak najdłużej.
              </p>
              <p>
                Twój uśmiech jest jednym z Twoich największych atutów. Dlatego stworzyliśmy zespół,
                któremu możesz zaufać.
              </p>
            </div>
          </div>
        </div>
        {/* <div className="container--pad">
          <div className="about-us-more"></div>
        </div> */}
        <div className="container--pad">
          <div className="facts">
            <div className="facts__layout">
              <FormattedMessage id="o-nas.liczby.h2" tagName="h2" />
              <ul className="facts__list">
                <FormattedMessage id="o-nas.liczby.li.1" tagName="li" />
                <FormattedMessage id="o-nas.liczby.li.2" tagName="li" />
                <FormattedMessage id="o-nas.liczby.li.3" tagName="li" />
                <FormattedMessage id="o-nas.liczby.li.4" tagName="li" />
                <FormattedMessage id="o-nas.liczby.li.5" tagName="li" />
              </ul>
            </div>
          </div>
        </div>
        <div className="container--pad grid-1-2 about-us-aesthetic">
          <figure className="picture">
            <FormattedMessage id="o-nas.marcin-tutak.pictureCaption">
              {(msg) => (
                <>
                  <Img
                    fluid={data.marcinTutak.childImageSharp.fluid}
                    className="image"
                    imgStyle={{
                      objectFit: "cover",
                      objectPosition: "center center",
                    }}
                    fadeIn={true}
                    alt={msg}
                  />
                  <PictureCaption caption={msg} />
                </>
              )}
            </FormattedMessage>
          </figure>
          <div>
            <h2>Prawdziwa klinika stomatologii estetycznej</h2>
            <p>
              Dr Marcin Tutak jest członkiem Europejskiej Akademii Stomatologii Estetycznej, jak i
              Polskiej Akademii Stomatologii Estetycznej. Obie organizacje zrzeszają liderów w
              dziedzinie stomatologii estetycznej, w kraju i za granicą.
            </p>
            <p>
              Uczestnictwo w organizacjach zapewnia całemu naszemu zespołowi stały kontakt i
              współpracę z najlepszymi ośrodkami stomatologicznymi w Polsce i na świecie.
            </p>
            <a href="https://pase.org.pl/" className="link with-icon">
              Strona Główna PASE <Out />
            </a>
          </div>
        </div>
      </main>
    </>
  );
};

const query = graphql`
  query {
    heroImage1: file(relativePath: { eq: "o-nas hero desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    heroImage2: file(relativePath: { eq: "o-nas hero mobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    marcinTutak: file(relativePath: { eq: "o-nas marcin-tutak.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

export default ONasPage;
