import React from "react";
import classNames from "classnames";

import { dropdown, dropdownVisible } from "./NavMobile.module.scss";

const Dropdown = ({ visible, children }) => {
  return (
    <div className={classNames(dropdown, visible && dropdownVisible)}>
      <div>{children}</div>
    </div>
  );
};

export default Dropdown;
