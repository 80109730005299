import Breadcrumbs from "components/Breadcrumbs";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Services from "common/Services/Services";
import Layout from "components/Layout";
import PictureCaption from "components/PictureCaption";
import SEO from "components/Seo";
import * as styles from "styles/ofertaDetail.module.scss";

const services = [
  { key: "licowki-i-kompozyty" },
  { key: "stomatologia-estetyczna" },
  { key: "stomatologia-zachowawcza" },
];

const StomatologiaMaloinwazyjnaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "oferta/biomimetyka-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      image2: file(relativePath: { eq: "oferta/biomimetyka-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  const { formatMessage } = useIntl();

  return (
    <>
      <SEO
        title={formatMessage({
          id: "oferta.stomatologia-maloinwazyjna.seo.title",
        })}
        description={formatMessage({
          id: "oferta.stomatologia-maloinwazyjna.seo.description",
        })}
      />
      <main>
        <article className={styles.article}>
          <section>
            <h1>{formatMessage({ id: "oferta.stomatologia-maloinwazyjna.h1" })}</h1>
            <div className={styles.text}>
              <FormattedMessage id="oferta.stomatologia-maloinwazyjna.p.1" tagName="p" />
              <FormattedMessage id="oferta.stomatologia-maloinwazyjna.p.2" tagName="p" />
              <FormattedMessage id="oferta.stomatologia-maloinwazyjna.p.3" tagName="p" />
            </div>
            <div className="double-picture-row">
              <figure className={styles.picture}>
                <FormattedMessage id="oferta.stomatologia-maloinwazyjna.picture.1">
                  {(msg) => (
                    <>
                      <Img fluid={data.image1.childImageSharp.fluid} alt={msg} />
                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
              <figure className={styles.picture}>
                <FormattedMessage id="oferta.stomatologia-maloinwazyjna.picture.2">
                  {(msg) => (
                    <>
                      <Img fluid={data.image2.childImageSharp.fluid} alt={msg} />

                      <PictureCaption caption={msg} />
                    </>
                  )}
                </FormattedMessage>
              </figure>
            </div>
          </section>
          <section>
            <Services services={services} />
          </section>
        </article>
      </main>
    </>
  );
};

export default StomatologiaMaloinwazyjnaPage;
