import { StaticQuery, graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { useLocation } from "@reach/router";

import icon32 from "../images/logos/aestheticdent-32-favico.png";

const SEO = ({ description, lang, meta, title, imageUrl }) => {
  const { pathname } = useLocation();
  let truePathname;
  if (pathname.startsWith("/en/") || pathname.startsWith("/de/")) {
    truePathname = pathname.slice(3);
  } else {
    truePathname = pathname;
  }

  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              { charSet: "utf-8" },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: `image`,
                content: imageUrl,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:url`,
                content: data.site.siteMetadata.siteUrl + truePathname,
              },
              {
                property: `og:type`,
                content: `website`,
              },
            ].concat(meta)}
            link={[
              { rel: "shortcut icon", type: "image/png", href: `${icon32}` },
              {
                rel: "canonical",
                href: `${data.site.siteMetadata.siteUrl}${pathname !== "/" ? `${pathname}` : ""}`,
              },
              {
                rel: "alternate",
                href: `${data.site.siteMetadata.siteUrl}${
                  truePathname !== "/" ? `${truePathname}` : ""
                }`,
                hrefLang: "pl-PL",
              },
              // {
              //   rel: 'alternate',
              //   href: `${data.site.siteMetadata.siteUrl}/en${truePathname}`,
              //   hreflang: 'en',
              // },
              // {
              //   rel: 'alternate',
              //   href: `${data.site.siteMetadata.siteUrl}/de${truePathname}`,
              //   hreflang: 'de',
              // },
            ]}
          />
        );
      }}
    />
  );
};

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
};

export default SEO;

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }

    allSitePage {
      edges {
        node {
          path
        }
      }
    }
  }
`;
