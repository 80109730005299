import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "react-intl";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import LocalizedLink from "components/LocalizedLink";
import SEO from "components/Seo";

const moveMemberToPosition = (array, memberName, position) => {
  position = Math.max(0, position - 1);

  const member = array.find(({ node }) => node.name === memberName);
  const memberIndex = array.findIndex(({ node }) => node.name === memberName);

  if (!member || memberIndex === -1) return array;

  const newArray = [...array];
  newArray.splice(memberIndex, 1);
  newArray.splice(position, 0, member);
  return newArray;
}

const Component = ({ data }) => {
  const { edges = [] } = data.allStrapiTeamMember;
  
  const teamData = moveMemberToPosition(edges, 'Paulo Malo', 3);

  const { formatMessage: t } = useIntl();

  return (
    <main className="main max">
      <SEO
        title={t({
          id: "zespol.seo.title",
        })}
        description={t({ id: "zespol.seo.description" })}
      />
      <h1 className="h px-m">{t({ id: 'zespol.h1' })}</h1>
      <div className="grid gap-y-8 gap-x-4 lg:gap-x-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:px-m">
        {teamData.map(({ node }) => (
          <div key={node.id}>
            <LocalizedLink to={node.pathname} state={{
              modal: true,
              shouldPreventScroll: true
            }}
            >
              <GatsbyImage image={getImage(node.picture?.localFile)} alt={node.picture?.alternativeText} className="mb-2 md:mb-4" imgClassName="max-h-[32rem] md:max-h-none" />
              <div className="px-4 md:px-0 decoration-blue-700">
                <span className="font-lato text-blue-700 decoration-blue-700 text-sm">{node.title}</span>
                <h3 className="h text-lg text-blue-700 decoration-blue-700 -mt-1 mb-1">
                  {node.name}
                </h3>
              </div>
            </LocalizedLink>
          </div>
        ))}
      </div>
    </main>
  );
};

// export const Head = ({ data }) => {
//   return <>

//   </>
// }


export const query = graphql`
  query AllStrapiTeamMemeber {
    allStrapiTeamMember {
      edges {
        node {
          seo {
            metaDescription
            metaTitle
            metaImage {
              formats {
                large {
                  name
                  hash
                  ext
                  mime
                  width
                  height
                  size
                  url
                }
              }
            }
          }
          picture {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  aspectRatio: 0.66
                  transformOptions: {fit: COVER, cropFocus: CENTER}
                )
              }
            }
          }
          id
          name
          title
          pathname: gatsbyPath(filePath:"/zespol/{strapiTeamMember.name}")
        }
      }
    }
}
`;

export default Component;
