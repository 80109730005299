import { useLocation } from "@reach/router";
import React from "react";
import { useIntl } from "react-intl";

import JsonLd from "common/JsonLd";
import splitPathName from "utils/splitPathName";
import icon from "images/svg/ad-small.svg";

import LocalizedLink from "../LocalizedLink";
import * as styles from "./Breadcrumbs.module.scss";

const hasTranslation = (t, id) => {
  if (t({ id }) === id) {
    return false;
  }

  return true;
}

const Breadcrumbs = ({ pageContext }) => {
  const { pathname, origin } = useLocation();

  if (pathname.startsWith("/en/") || pathname.startsWith("/de/")) {
    pathname = pathname.slice(3);
  }

  let breadcrumbs = splitPathName(pathname);

  const { formatMessage: t } = useIntl();

  const listItems = [];
  let path = origin + "/";

  breadcrumbs.slice(0, -1).forEach((curr, ix) => {
    path += curr + "/";
    listItems.push({
      "@type": "ListItem",
      position: ix + 1,
      item: path,
      name: t({ id: `breadcrumb.${curr}` }),
    });
  });

  const { title } = pageContext;

  const lastItemKey = breadcrumbs.at(-1);
  const lastItemName = title ?? (hasTranslation(t, `breadcrumb.${lastItemKey}`) ? t({ id: `breadcrumb.${lastItemKey}` }) : '');

  listItems.push({
    "@type": "ListItem",
    position: breadcrumbs.length,
    name: lastItemName,
  });

  const shouldRenderBreadcrumbs = pathname !== "/";

  return shouldRenderBreadcrumbs ? (
    <>
      <JsonLd>
        {{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: listItems,
        }}
      </JsonLd>
      <div className={styles.breadcrumbs} aria-label="Breadcrumbs" role="navigation">
        <div className={styles.layout}>
          <ul className={styles.list}>
            <li>
              <LocalizedLink to="/" className={styles.home}>
                <img src={icon} alt="Logo" />
              </LocalizedLink>
            </li>
            {breadcrumbs.slice(0, -1).map((value, ix) => {
              return (
                <li key={ix}>
                  <LocalizedLink
                    to={"/" + breadcrumbs.slice(0, ix + 1).join("/")}
                    className={styles.link}
                  >
                    {t({ id: `breadcrumb.${value}` })}
                  </LocalizedLink>
                </li>
              );
            })}
            <li>{lastItemName}</li>
          </ul>
        </div>
      </div>
    </>
  ) : null;
};

export default Breadcrumbs;
