import React, { useRef, useState } from "react";
import { range } from "utils/range";

import useIsomorphicLayoutEffect from "hooks/useIsomorphicLayoutEffect";
import * as styles from "./Slider.module.scss";

const Slider = ({ current, children }) => {
  const wrapperRef = useRef(null);
  const [pageWidth, setPageWidth] = useState(0);

  const pages = Array.from(children).length;

  useIsomorphicLayoutEffect(() => {
    if (wrapperRef.current) {
      setPageWidth(wrapperRef.current.clientWidth);
    }
  }, []);


  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div
        className={styles.inner}
        style={{ width: pageWidth * pages, marginLeft: -Math.abs(current) * pageWidth }}
      >
        {children}
      </div>
      <div className={styles.dots}>
        {range(1, pages).map((_val, ix) => (
          <div className={`${styles.dot} ${current === ix ? styles.active : ""}`} key={ix} />
        ))}
      </div>
    </div>
  );
};

export default Slider;
