import React from "react";
import PropTypes from "prop-types";

import LocalizedLink from "components/LocalizedLink";

import { button } from "./Button.module.scss";

export const Button = ({ to, children, ...attrs }) => {
  return (
    <LocalizedLink to={to} className={button} {...attrs}>
      {children}
    </LocalizedLink>
  );
};

Button.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string,
};

export default Button;
