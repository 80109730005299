import React from "react";

import LocalizedLink from "components/LocalizedLink";
import logo from "images/logos/aestheticdent_logo_alt.svg";

import NavDesktop from "./NavDesktop";
import NavMobile from "./NavMobile";

import * as styles from "./Header.module.scss";
import { MMediaDown, MMediaUp } from "common/Media";

const Header = () => {
  return (
    <header className={styles.header}>
      <MMediaUp>
        <div className={styles.layout}>
          <div className={styles.logo}>
            <LocalizedLink to="/">
              <img src={logo} alt="Aesthetic Dent" />
            </LocalizedLink>
          </div>
          <NavDesktop />
        </div>
      </MMediaUp>
      <MMediaDown>
        <div className={styles.layout}>
          <div className={styles.logo}>
            <LocalizedLink to="/">
              <img src={logo} alt="Aesthetic Dent" />
            </LocalizedLink>
          </div>
          <NavMobile />
        </div>
      </MMediaDown>
    </header>
  );
};

export default Header;
